import { Suspense } from 'react';
import { Outlet, useRoutes } from 'react-router-dom';

import LoginPage from 'src/pages/login';
import SignupPage from 'src/pages/signup';
import ProfilePage from 'src/pages/profile';
import AdminUsersPage from 'src/pages/admin-users';
import DashboardLayout from 'src/layouts/dashboard';
import AdminLocalizationPage from 'src/pages/admin-localization';
import AdminServiceModelPage from 'src/pages/admin-service-model';
import AdminServiceModelExecutionPage from 'src/pages/admin-service-model-executions';

import { ServiceModelView } from 'src/sections/service-model/view';

import ProtectedRoute from './protected-route';


export default function Router() {
    const routes = useRoutes([
        {
            element: (
                <ProtectedRoute>
                    <DashboardLayout>
                        <Suspense>
                            <Outlet />
                        </Suspense>
                    </DashboardLayout>
                </ProtectedRoute>
            ),
            children: [
                {
                    element:
                        <ProtectedRoute>
                            {/* <IndexPage /> */}
                            <ServiceModelView />
                        </ProtectedRoute>, index: true
                },
                {
                    path: "profile",
                    element:
                        <ProtectedRoute>
                            <ProfilePage />
                        </ProtectedRoute>
                },
                {
                    path: "service-model", element:
                        <ProtectedRoute>
                            <ServiceModelView />
                        </ProtectedRoute>
                },

                {
                    path: "admin/users", element:
                        <ProtectedRoute>
                            <AdminUsersPage />
                        </ProtectedRoute>
                },
                {
                    path: 'admin/users/:userID/profile', element:
                        <ProtectedRoute>
                            <ProfilePage />
                        </ProtectedRoute>
                },
                {
                    path: 'admin/service-model', element:
                        <ProtectedRoute requiredAdmin>
                            <AdminServiceModelPage />
                        </ProtectedRoute>
                },
                {
                    path: 'admin/service-model-executions', element:
                        <ProtectedRoute requiredAdmin>
                            <AdminServiceModelExecutionPage />
                        </ProtectedRoute>
                },
                {
                    path: 'admin/localization', element:
                        <ProtectedRoute requiredAdmin>
                            <AdminLocalizationPage />
                        </ProtectedRoute>
                },
            ],
        },
        {
            path: "signup",
            element: <SignupPage />
        },
        {
            path: "login",
            element: <LoginPage />
        }
    ]);

    return routes;
}