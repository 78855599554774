import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { Navigate } from "react-router-dom";

import { Box, CircularProgress } from '@mui/material';

import { readSessionToken } from "src/utils/utilities";
import { ApiError, getUserProfile, SvcErrID_Unauthorized, SvcErrID_RequirePasswordChanging } from 'src/utils/api';

function ProtectedRoute({ redirectPath = '/login', requiredAdmin = false, children }) {
    const [userProfile, setUserProfile] = useState(null);
    const [requirePasswordChanging, setRequirePasswordChanging] = useState(false);
    const [isGettingUserProfile, setIsGettingUserProfile] = useState(true);

    const [forceChangePasswordArgs, setForceChangePasswordArgs] = useState({ userID: "", email: "", token: "" });

    useEffect(() => {
        (async () => {
            const sessionToken = readSessionToken();
            if (!sessionToken) {
                setIsGettingUserProfile(false);
                return;
            }

            try {
                const { userProfile: userProfile_, err } = await getUserProfile()
                setIsGettingUserProfile(false);
                if (err) {
                    console.log(err);
                    if (err.errID === SvcErrID_RequirePasswordChanging) {
                        const respHeader = ApiError.getResponseHeader(err);
                        console.log(respHeader);
                        const token = respHeader["c4t-change-password-token"];
                        const email = respHeader["c4t-change-password-for-email"];
                        const userID = respHeader["c4t-change-password-for-user-id"];
                        setForceChangePasswordArgs({ userID, email, token });
                        setRequirePasswordChanging(true);
                    }
                    return;
                }
                setUserProfile(userProfile_);
            } catch (err) {
                setIsGettingUserProfile(false);
                if (err.errID === SvcErrID_Unauthorized) {
                    return;
                }
            } finally {
                setIsGettingUserProfile(false);
            }
        })();
    }, [])

    if (isGettingUserProfile)
        return <Box sx={{ display: 'flex' }}>
            <CircularProgress />
        </Box>;

    if (requirePasswordChanging)
        return <Navigate to={`/force-reset-password?changePasswordToken=${forceChangePasswordArgs.token}&email=${forceChangePasswordArgs.email}&userID=${forceChangePasswordArgs.userID}`} replace />

    let navigateToLogin = true;
    if (userProfile) {
        // path requires admin but profile is not admin authorized
        if (requiredAdmin && userProfile?.isAdmin) navigateToLogin = false;
        // path does not require admin, but any profile is ok
        if (!requiredAdmin) navigateToLogin = false;
    }

    if (navigateToLogin) {
        return <Navigate to={redirectPath} replace />;
    }
    return children;
};

// prop types
ProtectedRoute.propTypes = {
    redirectPath: PropTypes.string,
    requiredAdmin: PropTypes.bool,
    children: PropTypes.node,
}

export default ProtectedRoute;