import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import { listAppLocalizations } from "src/utils/api";

import { pageTopMargin } from "src/layouts/dashboard/config-layout";

import SectionHeader from "src/components/section-header/header";

import LocalizationTable from "../table";
import LocalizationEditorDialog from "../dialog";

export default function LocalizationPage() {
  const { t } = useTranslation();

  const newLocalization = () => ({
    languageCode: "",
    language: "",
    iconifyName: "",
    resource: JSON.stringify({}, null, 2),
  });

  const [localizations, setLocalizations] = useState([]);
  const [editorDialogParams, setEditorDialogParams] = useState({
    open: false,
    editingLocalization: newLocalization(),
  });


  const reloadLocalizations = async () => {
    const { err, localizations: localizations_ } = await listAppLocalizations();
    if (err) {
      console.error(`failed to list app localizations: ${err}`);
      return;
    }
    setLocalizations(localizations_);
  };

  useEffect(() => {
    reloadLocalizations();
  }, []);

  return (
    <Container maxWidth="xl" sx={{ mt: pageTopMargin }}>
      <SectionHeader>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h5">
            {t("admin_localization.header", "Localization")}
          </Typography>
          <Button variant="contained" color="primary" size="medium" sx={{ ml: 4 }} onClick={() => {
            setEditorDialogParams({
              ...editorDialogParams,
              open: true,
              editingLocalization: newLocalization(),
            });
          }}>
            {t("admin_localization.create_button", "Create")}
          </Button>
        </Stack>
      </SectionHeader>
      <LocalizationTable localizations={localizations} sx={{ mt: 4 }} onEdit={(l) => {
        setEditorDialogParams({
          open: true,
          editingLocalization: l,
        });
      }} />
      <LocalizationEditorDialog
        localization={editorDialogParams.editingLocalization}
        isOpen={editorDialogParams.open}
        onClose={() => {
          reloadLocalizations();
          setEditorDialogParams({
            ...editorDialogParams,
            open: false,
          })
        }}
      />
    </Container>
  );
}