import axios from "axios";

import EnvWrapper from "src/env-wrapper";
import { UserProfile } from "src/model/profile";
import { ServiceModel } from "src/model/service-model";

import { encrypt } from "./encryption";
import { isImpersonated, readSessionToken, getCurrentLanguage } from "./utilities";

export const host = () =>
  EnvWrapper.API_SERVER_HOSTNAME() ||
  "http://localhost:9010";

export const SvcErrID_Unauthorized = 10003;
export const SvcErrID_RequirePasswordChanging = 30001;

export const defaultHeaders = () => {
  let h = { "x-client-version": "1.0" };
  const token = readSessionToken();
  if (token) {
    h = { ...h, "x-session-token": token };
  }
  return h;
}

export class ApiError {
  constructor() {
    this.errMsg = "";
    this.errID = "";
    // axiosResp: AxiosResponse
    this.axiosResp = null;
  }

  static fromObject(obj) {
    const err = new ApiError();
    err.errMsg = obj.errMsg;
    err.errID = obj.errID;
    if (obj.response) {
      err.axiosResp = obj.response;
    }
    return err;
  }

  static newError(errID, errMsg) {
    return ApiError.fromObject({
      errMsg,
      errID,
    });
  }

  /**
   * 
   * @param {ApiError} err 
   * @returns 
   */
  static getResponseHeader(err) {
    return err.axiosResp.headers;
  }
}

// GetResponseError extracts and returns an error from resp (response)
// @returns {
// errMsg: string
// errID: number
// }
export const GetResponseError = (resp) => {
  if (!resp.data.errors) return undefined;
  const err = ApiError.fromObject({
    errMsg: resp.data.errors || "",
    errID: resp.data.statusCode ? resp.data.statusCode : undefined,
    response: resp,
  });
  return err;
}

async function get(url) {
  let resp = {};
  try {
    resp = await axios.get(
      url,
      { headers: { ...defaultHeaders() } },
    );
  } catch (err) {
    resp = err.response;
    return {
      err: GetResponseError(resp),
    }
  }

  const { data } = resp.data;
  if (!resp.data.success) {
    return {
      err: GetResponseError(resp),
    }
  }

  return { data };
}

/**
 * doDelete (we cannot use name "delete" since it is reserve word)
 * @param {*} url 
 * @returns 
 */
async function doDelete(url) {
  let resp = {};
  try {
    resp = await axios.delete(
      url,
      { headers: { ...defaultHeaders() } },
    );
  } catch (err) {
    resp = err.response;
    return {
      err: GetResponseError(resp),
    }
  }

  const { data } = resp.data;
  if (!resp.data.success) {
    return {
      err: GetResponseError(resp),
    }
  }

  return { data };
}

async function postWithBody(url, body) {
  let resp = {};
  try {
    resp = await axios.post(
      url,
      body,
      { headers: { ...defaultHeaders() } },
    );
  } catch (err) {
    resp = err.response;
    return {
      err: GetResponseError(resp),
    }
  }

  const { data } = resp.data;
  if (!resp.data.success) {
    return {
      err: GetResponseError(resp),
    }
  }

  return { data };
}


async function putWithBody(url, body) {
  let resp = {};
  try {
    resp = await axios.put(
      url,
      body,
      { headers: { ...defaultHeaders() } },
    );
  } catch (err) {
    resp = err.response;
    return {
      err: GetResponseError(resp),
    }
  }

  const { data } = resp.data;
  if (!resp.data.success) {
    return {
      err: GetResponseError(resp),
    }
  }

  return { data };
}

export async function signupWithEmailAndPassword(email, password) {
  let resp = {};
  try {
    resp = await axios.post(
      `${host()}/api/v1/auth/signup/emailpassword`,
      {
        email,
        password,
      },
      { headers: { ...defaultHeaders() } },
    );
  } catch (err) {
    resp = err.response;
  }
  return resp;
}

export async function loginWithEmailAndPassword(email, password) {
  let resp = {};
  try {
    resp = await axios.post(
      `${host()}/api/v1/auth/login/emailpassword`,
      {
        email,
        password,
      },
      { headers: { ...defaultHeaders() } },
    );
  } catch (err) {
    resp = err.response;
  }
  return resp;
}

// getProfile
// @returns { err: string, userProfile: UserProfile }
export async function getUserProfile() {
  let resp = {};
  try {
    resp = await axios.get(
      `${host()}/api/v1/c4t/me/profile`,
      { headers: { ...defaultHeaders() } },
    );
  } catch (err) {
    return {
      err: GetResponseError(err.response),
    }
  }
  return {
    userProfile: UserProfile.fromObject(resp.data.data.userProfile),
  };
}

// adminGetProfile
// @returns { err: string, userProfile: UserProfile }
export async function adminGetUserProfile({ userID }) {
  let resp = {};
  try {
    resp = await axios.get(
      `${host()}/api/v1/c4t-admin/user/${userID}/profile`,
      { headers: { ...defaultHeaders() } },
    );
  } catch (err) {
    resp = err.response;
    return {
      err: resp.errors,
    }
  }
  return {
    userProfile: resp.data.data.userProfile,
  };
}

// updateProfile
// @returns { err: string }
export async function updateUserProfile({
  firstName,
  lastName,
  companyName,
  companyAddress,
  phoneNumber,
  jobTitleOrRole,
  vatNumber,
}) {
  let resp = {};
  try {
    resp = await axios.post(
      `${host()}/api/v1/c4t/me/profile`,
      {
        profile: {
          firstName,
          lastName,
          companyName,
          companyAddress,
          phoneNumber,
          jobTitleOrRole,
          vatNumber,
        }
      },
      { headers: { ...defaultHeaders() } },
    );
  } catch (err) {
    resp = err.response;
    return {
      err: GetResponseError(resp),
    }
  }

  return {
    err: GetResponseError(resp),
  }
}

export async function updatePreferredLanguage({
  language,
}) {
  let resp = {};
  try {
    resp = await axios.post(
      `${host()}/api/v1/c4t/me/preferred-language`,
      { language },
      { headers: { ...defaultHeaders() } },
    );
  } catch (err) {
    resp = err.response;
    return {
      err: GetResponseError(resp),
    }
  }
  return {
    err: GetResponseError(resp),
  };
}

// adminUpdateProfile
// @returns { err: string }
export async function adminUpdateUserProfile({
  userID,
  firstName,
  lastName,
  companyName,
  companyAddress,
  phoneNumber,
  jobTitleOrRole,
  vatNumber,
}) {
  let resp = {};
  try {
    resp = await axios.post(
      `${host()}/api/v1/c4t-admin/user/${userID}/profile`,
      {
        profile: {
          firstName,
          lastName,
          companyName,
          companyAddress,
          phoneNumber,
          jobTitleOrRole,
          vatNumber,
        }
      },
      { headers: { ...defaultHeaders() } },
    );
  } catch (err) {
    resp = err.response;
    return {
      err: GetResponseError(resp),
    }
  }

  return {
    err: GetResponseError(resp),
  }
}

export async function logout() {
  const resp = await axios.post(
    `${host()}/api/v1/auth/logout`,
    {},
    { headers: { ...defaultHeaders() } },
  );
  return resp;
}


/**
 * resetPasswordByUser
 * @param {Object} args
 * @param {string} args.oldPassword
 * @param {string} args.newPassword
 */
export async function resetPasswordByUser({
  oldPassword,
  newPassword
}) {
  let resp = {};
  try {
    resp = await axios.post(
      `${host()}/api/v1/c4t/me/reset-password`,
      {
        oldPassword,
        newPassword,
      },
      { headers: { ...defaultHeaders() } },
    );
  } catch (err) {
    resp = err.response;
    return {
      err: GetResponseError(resp),
    }
  }

  return {
    err: GetResponseError(resp),
  }
}


/**
 * resetPasswordByAdmin
 * @param {Object} args
 * @param {string} args.newPassword
 */
export async function resetPasswordByAdmin({
  userID,
  newPassword
}) {
  let resp = {};
  try {
    resp = await axios.post(
      `${host()}/api/v1/c4t-admin/user/${userID}/reset-password`,
      {
        newPassword,
      },
      { headers: { ...defaultHeaders() } },
    );
  } catch (err) {
    resp = err.response;
    return {
      err: GetResponseError(resp),
    }
  }

  return {
    err: GetResponseError(resp),
  }
}

/**
 * resetPasswordWithToken
 * @param {Object} args
 * @param {string} args.userID
 * @param {string} args.changePasswordToken
 * @param {string} args.newPassword
 */
export async function resetPasswordWithChangePasswordToken({
  userID,
  changePasswordToken,
  newPassword
}) {
  const payload = JSON.stringify({
    userID,
    changePasswordToken,
    newPassword
  });
  const payloadB64 = btoa(payload);

  let resp = {};
  try {
    resp = await axios.post(
      `${host()}/api/v1/c4t/reset-password-with-changing-password-token?payload=${payloadB64}`,
      {},
      { headers: { ...defaultHeaders() } },
    );
  } catch (err) {
    resp = err.response;
    return {
      err: GetResponseError(resp),
    }
  }

  return {
    err: GetResponseError(resp),
  }
}

/**
 * 
 */
export async function getUserCredits() {
  let resp = {};
  try {
    resp = await axios.get(
      `${host()}/api/v1/c4t/me/credits`,
      { headers: { ...defaultHeaders() } },
    );
  } catch (err) {
    resp = err.response;
    return {
      err: GetResponseError(resp),
    }
  }

  return {
    credits: resp.data.data.credits,
  }
}

/**
 * 
 */
export async function adminGetUserCredits(userID) {
  let resp = {};
  try {
    resp = await axios.get(
      `${host()}/api/v1/c4t-admin/user/${userID}/credit`,
      { headers: { ...defaultHeaders() } },
    );
  } catch (err) {
    resp = err.response;
    return {
      err: GetResponseError(resp),
    }
  }

  return {
    credits: resp.data.data.credits,
  }
}

/**
 * 
 * @param {string} userID
 * @param {string} serviceModelID 
 * @param {amount} amount 
 */
export async function topup(userID, serviceModelID, amount) {
  // user/{userID}/activation
  let resp = {};
  try {
    resp = await axios.post(
      `${host()}/api/v1/c4t-admin/user/${userID}/topup`,
      { serviceModelID, amount },
      { headers: { ...defaultHeaders() } },
    );
  } catch (err) {
    resp = err.response;
    return {
      err: GetResponseError(resp),
    }
  }

  const { credit } = resp.data.data;
  return { credit };
}


/**
 * 
 * @param {string} userID
 * @param {string} serviceModelID 
 * @param {amount} amount 
 */
export async function topup2(userID, serviceModelID, amount) {
  // user/{userID}/activation
  const { err, data } = postWithBody(
    `${host()}/api/v1/c4t-admin/user/${userID}/topup`,
    { serviceModelID, amount },
  );
  if (err) return { err };

  const { credit } = data;
  return { credit };
}


/**
 * 
 * @param {string} userID
 * @param {string} serviceModelID 
 * @param {amount} amount 
 */
export async function decreaseCredit(userID, serviceModelID, amount) {
  if (amount <= 0) {
    return ApiError.newError("", "amount must be greater than 0");
  }

  // user/{userID}/activation
  const { err, data } = await postWithBody(
    `${host()}/api/v1/c4t-admin/user/${userID}/credit-adjustment/decrease`,
    { serviceModelID, amount },
  );
  if (err) return { err };

  const { credit } = data;
  return { credit };
}

/**
 * 
 */
export async function adminListUsers() {
  let resp = {};
  try {
    resp = await axios.get(
      `${host()}/api/v1/c4t-admin/user/profiles`,
      { headers: { ...defaultHeaders() }, data: {} },
    );
  } catch (err) {
    resp = err.response;
    return {
      err: GetResponseError(resp),
    }
  }

  return {
    userProfiles: [...resp.data.data.userProfiles].map(e => UserProfile.fromObject(e)),
  }
}

/**
 * 
 * @param {bool} activate 
 * @param {string} userID
 */
export async function adminSetUserActivation(userID, activate) {
  // user/{userID}/activation
  let resp = {};
  try {
    resp = await axios.post(
      `${host()}/api/v1/c4t-admin/user/${userID}/activation`,
      { activate },
      { headers: { ...defaultHeaders() } },
    );
  } catch (err) {
    resp = err.response;
    return {
      err: GetResponseError(resp),
    }
  }

  return {};
}

export async function listAvailableServiceModels() {
  let resp = {};
  try {
    resp = await axios.get(
      `${host()}/api/v1/c4t/me/available-service-model`,
      { headers: { ...defaultHeaders() } },
    );
  } catch (err) {
    return {
      err: GetResponseError(err.response),
    }
  }
  return {
    serviceModels: [...resp.data.data.serviceModels].map(e => ServiceModel.fromObject(e)),
  };
}

export async function adminListAvailableServiceModels(userID) {
  let resp = {};
  try {
    resp = await axios.get(
      `${host()}/api/v1/c4t-admin/user/${userID}/available-service-model`,
      { headers: { ...defaultHeaders() } },
    );
  } catch (err) {
    return {
      err: GetResponseError(err.response),
    }
  }
  return {
    serviceModels: [...resp.data.data.serviceModels].map(e => ServiceModel.fromObject(e)),
  };
}

export async function createServiceModelExecution(serviceModelID) {
  let resp = {};
  try {
    resp = await axios.post(
      `${host()}/api/v1/c4t/me/service-model/${serviceModelID}/execution`,
      {},
      { headers: { ...defaultHeaders() } },
    );
  } catch (err) {
    resp = err.response;
    return {
      err: GetResponseError(resp),
    }
  }

  return {
    execution: resp.data.data.execution,
  }
}

export async function submitServiceModelExecutionInputFile(serviceModelID, executionID, file) {
  let resp = {};
  try {
    const form = new FormData();
    form.append('file', file);
    resp = await axios.postForm(
      `${host()}/api/v1/c4t/me/service-model/${serviceModelID}/execution/${executionID}/input-file`,
      form,
      { headers: { ...defaultHeaders() } },
    );
  } catch (err) {
    resp = err.response;
    return {
      err: GetResponseError(resp),
    }
  }

  return {
    isFileValid: resp.data.data.isFileValid,
  }
}

export async function startServiceModelExecution(serviceModelID, executionID) {
  let resp = {};
  try {
    resp = await axios.post(
      `${host()}/api/v1/c4t/me/service-model/${serviceModelID}/execution/${executionID}/start`,
      {},
      { headers: { ...defaultHeaders() } },
    );
  } catch (err) {
    resp = err.response;
    return {
      err: GetResponseError(resp),
    }
  }

  return {};
}

export async function cancelServiceModelExecution(serviceModelID, executionID) {
  let resp = {};
  try {
    resp = await axios.post(
      `${host()}/api/v1/c4t/me/service-model/${serviceModelID}/execution/${executionID}/cancel`,
      {},
      { headers: { ...defaultHeaders() } },
    );
  } catch (err) {
    resp = err.response;
    return {
      err: GetResponseError(resp),
    }
  }

  return {};
}

export async function listServiceModelExecutions(params = {
  executionCreatedAtRangeFrom: null,
  executionCreatedAtRangeTo: null,
  offset: 0,
  limit: 0,
}) {
  // base64 encode
  const j = JSON.stringify(params);
  const b64 = btoa(j);

  let resp = {};
  try {
    resp = await axios.get(
      `${host()}/api/v1/c4t/me/service-model/execution?params=${b64}`,
      { headers: { ...defaultHeaders() } },
    );
  } catch (err) {
    return {
      err: GetResponseError(err.response),
    }
  }

  const {
    executions,
    numberOfTotalExecutions,
  } = resp.data.data;

  return {
    executions,
    numberOfTotalExecutions,
  };
}

export async function getFileEntityByFileID(fileID) {
  let resp = {};
  try {
    resp = await axios.get(
      `${host()}/api/v1/c4t/me/file-storage/file/${fileID}`,
      { headers: { ...defaultHeaders() } },
    );
  } catch (err) {
    return {
      err: GetResponseError(err.response),
    }
  }
  return {
    fileEntity: resp.data.data.fileEntity,
  };
}

export async function processServiceModelExecutionQueue() {
  let resp = {};
  try {
    resp = await axios.post(
      `${host()}/api/v1/c4t-admin/service-model-execution-queue/process-once`,
      {},
      { headers: { ...defaultHeaders() } },
    );
  } catch (err) {
    resp = err.response;
    return {
      err: GetResponseError(resp),
    }
  }
  return {}
}

export async function downloadServiceExecutionInputFileAsJSON(serviceModelID, executionID) {
  let resp = {};
  try {
    resp = await axios.get(
      `${host()}/api/v1/c4t/me/service-model/${serviceModelID}/execution/${executionID}/download-input-file-json`,
      { headers: { ...defaultHeaders() } },
    );
  } catch (err) {
    resp = err.response;
    return {
      err: GetResponseError(resp),
    }
  }

  return { jsonContent: resp.data.data.jsonContent };
}

export async function startImpersonate(targetUserID) {
  let resp = {};
  try {
    resp = await axios.post(
      `${host()}/api/v1/auth/admin/impersonate/start?targetUserID=${targetUserID}`,
      {},
      { headers: { ...defaultHeaders() } },
    );
  } catch (err) {
    resp = err.response;
    return {
      err: GetResponseError(resp),
    }
  }
  return { sessionToken: resp.data.data.session.token }
}

export async function stopImpersonate() {
  let resp = {};
  try {
    resp = await axios.post(
      `${host()}/api/v1/auth/admin/impersonate/stop`,
      {},
      { headers: { ...defaultHeaders() } },
    );
  } catch (err) {
    resp = err.response;
    return {
      err: GetResponseError(resp),
    }
  }
  return { originalSessionToken: resp.data.data.originalSessionToken }
}

export async function grantServiceModelPermissionToUser(userID, serviceModelID) {
  let resp = {};
  try {
    resp = await axios.post(
      `${host()}/api/v1/c4t-admin/service-model/${serviceModelID}/permission/grant?userID=${userID}`,
      {},
      { headers: { ...defaultHeaders() } },
    );
  } catch (err) {
    resp = err.response;
    return {
      err: GetResponseError(resp),
    }
  }
  return {}
}

export async function revokeServiceModelPermissionToUser(userID, serviceModelID) {
  let resp = {};
  try {
    resp = await axios.post(
      `${host()}/api/v1/c4t-admin/service-model/${serviceModelID}/permission/revoke?userID=${userID}`,
      {},
      { headers: { ...defaultHeaders() } },
    );
  } catch (err) {
    resp = err.response;
    return {
      err: GetResponseError(resp),
    }
  }
  return {}
}

export async function getServiceExecutionStats(serviceModelIDs) {
  if (serviceModelIDs.length === 0) {
    return { stats: [] };
  }

  const serviceModelID = serviceModelIDs[0];
  let url = `${host()}/api/v1/c4t/me/service-model/${serviceModelID}/execution/stat`;
  if (serviceModelIDs.length > 1) {
    url += `?serviceModelIDs=${serviceModelIDs.join(",")}`;
  }
  const { data, err } = await get(url);
  if (err) {
    return { err };
  }
  const { stats } = data;
  return { stats };
}

export async function adminGetServiceExecutionStats(serviceModelIDs) {
  if (serviceModelIDs.length === 0) {
    return { stats: [] };
  }

  const serviceModelID = serviceModelIDs[0];
  let url = `${host()}/api/v1/c4t-admin/service-model/${serviceModelID}/execution/stat`;
  if (serviceModelIDs.length > 1) {
    url += `?serviceModelIDs=${serviceModelIDs.join(",")}`;
  }
  const { data, err } = await get(url);
  if (err) {
    return { err };
  }
  const { stats } = data;
  return { stats };
}

export async function createServiceModel(serviceModel) {
  if (!serviceModel) {
    return {
      err: ApiError.newError("", "Invalid service model"),
    };
  }

  const url = `${host()}/api/v1/c4t-admin/service-model`;
  const { data, err } = await postWithBody(url, { serviceModel });
  if (err) {
    return { err };
  }

  const { newServiceModel } = data;
  return {
    newServiceModel,
  }
}

export async function updateServiceModel(serivceModelID, {
  name,
  detail,
  version,
  mpsAPIEndpoint,
  mpsFetchResultAPIEndpoint,
  mpsGetRequestInfoAPIEndpoint,
  mpsDeleteRequestAPIEndpoint,
  validateRuleSets,
  pythonCodeForGenerateOutput,
  creditCost,
  moreInfoURL,
  enabled,
}) {
  const url = `${host()}/api/v1/c4t-admin/service-model/${serivceModelID}`;
  const { data, err } = await putWithBody(url, {
    updatingFields: {
      name,
      detail,
      version,

      mpsAPIEndpoint,
      mpsFetchResultAPIEndpoint,
      mpsGetRequestInfoAPIEndpoint,
      mpsDeleteRequestAPIEndpoint,

      validateRuleSets,
      pythonCodeForGenerateOutput,
      creditCost,
      enabled,

      moreInfoURL,
    },
  });
  if (err) {
    return { err };
  }

  const { updatedServiceModel } = data;
  return { updatedServiceModel };
}

export async function deleteServiceModel(serviceModelID) {
  const url = `${host()}/api/v1/c4t-admin/service-model/${serviceModelID}`;
  const { err } = await doDelete(url);
  if (err) {
    return { err };
  }
  return {};
}

export async function adminListServiceModels() {
  const url = `${host()}/api/v1/c4t-admin/service-model`;
  const { data, err } = await get(url);
  if (err) {
    return { err };
  }
  const { serviceModels } = data;
  return { serviceModels };
}

export async function fetchNotifications() {
  const url = `${host()}/api/v1/c4t/me/notification`;
  const { data, err } = await get(url);
  if (err) {
    return { err };
  }
  const { notifications } = data;
  return { notifications };
}

export async function getAppLocalizationResource(languageCode) {
  const url = `${host()}/api/v1/c4t/localization/${languageCode}/resource`;
  const { data, err } = await get(url);
  if (err) {
    return { err };
  }

  const { resource } = data;
  return { resource };
}


export async function listAppLocalizations() {
  const url = `${host()}/api/v1/c4t/localization`;
  const { data, err } = await get(url);
  if (err) {
    return { err };
  }

  const { localizations } = data;
  return { localizations };
}

export async function addOrUpdateAppLocalization(languageCode, localization) {
  const url = `${host()}/api/v1/c4t-admin/localization/${languageCode}/resource`;
  const { err } = await postWithBody(url, { ...localization });
  if (err) {
    return { err };
  }

  // type AddOrUpdateAppLocalizationRequest struct {
  //   Language     string `json:"language"`
  //   LanguageCode string `json:"languageCode"`
  //   IconifyName  string `json:"iconifyName"`
  //   Resource     string `json:"resource"`
  // }

  return {}
}

export async function searchServiceExecutions(params = {
  serviceModelIDs: [],
  userIDs: [],
  userName: "",
  emailAddress: "",
  executionCreatedAtRangeFrom: null,
  executionCreatedAtRangeTo: null,
  executionStatuses: [],
  sorting: [],
  offset: 0,
  limit: 10,
}) {
  // base64 encode
  const j = JSON.stringify(params);
  const b64 = btoa(j);

  const url = `${host()}/api/v1/c4t-admin/service-model-execution/search?params=${b64}`;
  const { data, err } = await get(url);
  if (err) {
    return { err };
  }

  const { executions, numberOfTotalExecutions, profiles } = data;
  return { executions, numberOfTotalExecutions, profiles };
}


export async function manualSubmitSolutionFileForExecution(executionID, file) {
  let resp = {};
  try {
    const form = new FormData();
    form.append('file', file);
    resp = await axios.postForm(
      `${host()}/api/v1/c4t-admin/service-model-execution/${executionID}/submit-solution`,
      form,
      { headers: { ...defaultHeaders() } },
    );
  } catch (err) {
    resp = err.response;
    return {
      err: GetResponseError(resp),
    }
  }

  return {
    isFileValid: resp.data.data.isFileValid,
  }
}

export async function listCollectionOfMPSRequests(url) {
  const apiUrl = `${host()}/api/v1/c4t-admin/mps/list-request-collection?url=${encodeURIComponent(url)}`;
  const { data, err } = await get(apiUrl);
  if (err) {
    return { err };
  }

  const { rawResultInJSON } = data;
  return { rawResultInJSON };
}

export async function setEnablingServiceModel(serviceModelID, enabled) {
  if (!serviceModelID) {
    return {
      err: ApiError.newError("", "Invalid service model ID"),
    };
  }

  const url = `${host()}/api/v1/c4t-admin/service-model/${serviceModelID}/set-enable`;
  const { err } = await postWithBody(url, { enabled });
  if (err) {
    return { err };
  }

  return {};
}

export async function getIsJobQueueEnabled() {
  const url = `${host()}/api/v1/c4t-admin/server-config/is-job-queue-enabled`;
  const { data, err } = await get(url);
  if (err) {
    return { err };
  }

  const { enabled } = data;
  return { enabled };
}

export async function setJobQueueEnabled(enabled) {
  const url = `${host()}/api/v1/c4t-admin/server-config/set-job-queue-enabled`;
  const { err } = await putWithBody(url, { enabled });
  if (err) {
    return { err };
  }
  return {};
}

export async function getAzureConfig(eck) {
  const url = `${host()}/api/v1/c4t-admin/server-config/azure-config?eck=${eck}`;
  const { err, data } = await get(url);
  if (err) {
    return { err };
  }
  const { encryptedInBase64 } = data;
  return { encryptedInBase64 };
}

export async function saveAzureConfig(azureConfig, eck) {
  const j = JSON.stringify(azureConfig);
  const encrypted = encrypt(j, eck);
  const payload = `${eck}${encrypted}`;
  const url = `${host()}/api/v1/c4t-admin/server-config/azure-config`;
  const { err } = await postWithBody(url, { payload });
  if (err) {
    return { err };
  }
  return {};
}

export async function getApplicationGatewayOperationalState() {
  const url = `${host()}/api/v1/c4t-admin/server-config/application-gateway/operational-state`;
  const { err, data } = await get(url);
  if (err) {
    return { err };
  }
  const { state } = data;
  return { state };
}

export async function startApplicationGateway() {
  const url = `${host()}/api/v1/c4t-admin/server-config/application-gateway/start`;
  const { err, data } = await postWithBody(url, {});
  if (err) {
    return { err };
  }
  const { state } = data;
  return { state };
}

export async function stopApplicationGateway() {
  const url = `${host()}/api/v1/c4t-admin/server-config/application-gateway/stop`;
  const { err, data } = await postWithBody(url, {});
  if (err) {
    return { err };
  }
  const { state } = data;
  return { state };
}

export async function syncPreferredLanguage() {
  const savedLang = localStorage.getItem("preferred_language") ?? "-";
  const lang = getCurrentLanguage();
  if (savedLang === lang) {
    return;
  }
  localStorage.setItem("preferred_language", lang);
  const t = readSessionToken();
  if (t.length > 0 && !isImpersonated()) {
    updatePreferredLanguage({ language: lang });
  }
}

export async function deleteUserAndAllData(userID) {
  const url = `${host()}/api/v1/c4t-admin/user/${userID}/delete`;
  const { err } = await doDelete(url);
  if (err) {
    return { err };
  }
  return {};
}

// /service-model-execution/{executionID}/submit-solution

// /file-storage/file/{fileID}
// /service-model/{serviceModelID}/execution
// /service-model/{serviceModelID}/execution/{executionID}/input-file
// /service-model-execution-queue/process-once
// /service-model/{serviceModelID}/execution/{executionID}/start