export const APICommonErrorIDs = {
  InvalidArgument: 10001,
  NotFound: 10002,
  Unauthorized: 10003,
  ServerAccessLimited: 10004,
  ReachLimitedSessionPerUser: 10005,
  ReachLimitedSessionPerIP: 10006,
  AccountDoesNotActivate: 10007,
  ClientVersionIncompatible: 10010,
  Unhandled: 10900,
};