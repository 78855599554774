import Swal from "sweetalert2";
import PropTypes from "prop-types";
import FileSaver from "file-saver";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import { LoadingButton } from "@mui/lab";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Unstable_Grid2';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import { decrypt } from "src/utils/encryption";
import { randomAsciiString } from "src/utils/utilities";
import { getAzureConfig, saveAzureConfig, createServiceModel, updateServiceModel, setJobQueueEnabled, getIsJobQueueEnabled, adminListServiceModels, setEnablingServiceModel, listCollectionOfMPSRequests, adminGetServiceExecutionStats, processServiceModelExecutionQueue, getApplicationGatewayOperationalState, startApplicationGateway, stopApplicationGateway } from "src/utils/api";

import { ServiceModel } from "src/model/service-model";
import { pageTopMargin } from "src/layouts/dashboard/config-layout";
import { ExecutionStatus, executionStatusToString, executionStatusToStringTranslated } from "src/model/service-model-execution";

import Iconify from "src/components/iconify";
import SectionHeader from "src/components/section-header/header";

export default function ServiceModelView() {
  const { t } = useTranslation();

  const [processing, setProcessing] = useState(false);
  const [serviceModels, setServiceModels] = useState([]);
  const [serviceModelExecutionStats, setServiceModelExecutionStats] = useState([]);
  const [createServiceModelDialogTs, setCreateServiceModelDialogTs] = useState(Date.now());
  const [openServiceModelFormDialog, setOpenServiceModelFormDialog] = useState(false);

  const [editingServiceModel, setEditingServiceModel] = useState(null);
  const [editServiceModelDialogTs, setEditServiceModelDialogTs] = useState(Date.now());
  const [openEditingServiceModelFormDialog, setOpenEditingServiceModelFormDialog] = useState(false);

  const [isJobQueueEnabled, setIsJobQueueEnabled] = useState(false);

  const [azureConfigText, setAzureConfigText] = useState("");
  const [applicationGatewayOperationState, setApplicationGatewayOperationalState] = useState("unknown");

  const [downloadMPSRequestCollectionURL, setDownloadMPSRequestCollectionURL] = useState(localStorage.getItem("admin_service_model.download-mps-request-collection.last-download-url"));

  const reloadServiceModels = async () => {
    const { serviceModels: serviceModels_, err } = await adminListServiceModels();
    if (err) {
      return;
    }
    setServiceModels(serviceModels_);
  };

  const reloadExecutionStats = async (serviceModelIDs) => {
    const { stats, err } = await adminGetServiceExecutionStats(serviceModelIDs);
    if (err) {
      console.log("failed to reload execution stats:", err);
      return;
    }
    setServiceModelExecutionStats(stats);
  };

  const reloadAppGwState = async () => {
    const { state, err } = await getApplicationGatewayOperationalState();
    if (err) {
      console.log("failed to reload app gw state:", err);
      return;
    }
    setApplicationGatewayOperationalState(state);
  };

  useEffect(() => {
    (async () => {
      await reloadServiceModels();
      const { enabled: enabled_, err: err_ } = await getIsJobQueueEnabled();
      if (err_) {
        return;
      }
      setIsJobQueueEnabled(enabled_);
    })();
  }, []);

  useEffect(() => {
    if (!serviceModels) {
      return;
    }
    const serviceModelIDs = serviceModels.map(s => s.id);
    reloadExecutionStats(serviceModelIDs);
  }, [serviceModels]);

  useEffect(() => {
    (async () => {
      const eck = randomAsciiString(32);
      const { err, encryptedInBase64 } = await getAzureConfig(eck);
      if (err) {
        console.error("failed to get azure config:", err);
        return;
      }
      const decrypted = decrypt(encryptedInBase64, eck);
      const azureConfig = JSON.parse(decrypted);
      setAzureConfigText(JSON.stringify(azureConfig, null, 4));
    })();

    reloadAppGwState();
  }, []);

  return (
    <Container maxWidth="xl" sx={{ mt: pageTopMargin }}>
      <SectionHeader>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h5">
            {t("admin_service_model.header", "Services")}
          </Typography>
          <Button variant="contained" color="primary" size="medium" sx={{ ml: 4 }} onClick={() => {
            setOpenServiceModelFormDialog(true);
            setCreateServiceModelDialogTs(Date.now());
          }}>
            {t("admin_service_model.create_button", "Create")}
          </Button>
        </Stack>
      </SectionHeader>

      <CreateServiceModelFormDialog
        ts={createServiceModelDialogTs}
        openCreateServiceModelFormDialog={openServiceModelFormDialog}
        handleCreateServiceModelFormDialogClose={async () => {
          await reloadServiceModels();
          reloadExecutionStats(serviceModels.map(s => s.id));
          setOpenServiceModelFormDialog(false);
        }} />

      <Grid container sx={{ mb: 5 }} spacing={4}>
        {
          serviceModels.map(serviceModel => (
            <Grid xs={12} md={6} key={serviceModel.id}>
              <Card>
                <CardContent>
                  <Stack direction="row" alignItems="center">
                    <Iconify icon="ph:circle-fill" sx={{ color: serviceModel.enabled ? "#00e676" : "gray", width: 16, height: 16, mr: 1 }} />
                    <Typography variant="h4" color="text.secondary">
                      {t(`service_model.${serviceModel.id}.name`, serviceModel.name)}
                    </Typography>
                  </Stack>
                  <Typography variant="caption" sx={{ mb: 1.5 }} color="text.secondary">
                    {`ID ${serviceModel.id}`}
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 1.5 }} color="text.secondary">
                    {t("admin_service_model.service_model.credit_required", "Credit required:")} {serviceModel.creditCost}
                  </Typography>
                  <Typography variant="body2">
                    {t(`service_model.${serviceModel.id}.detail`, serviceModel.detail)}
                  </Typography>
                  <Grid container sx={{ mt: 2 }}>
                    <Stack direction="row" spacing={1} divider={<Divider orientation="vertical" flexItem />}>
                      {
                        [
                          ExecutionStatus.Provisioning,
                          ExecutionStatus.Pending,
                          ExecutionStatus.InProgress,
                          ExecutionStatus.Success,
                          ExecutionStatus.Fail,
                          // ExecutionStatus.Canceled,
                        ].map(status => {
                          const stat = serviceModelExecutionStats.filter(s => s.serviceModelID === serviceModel.id);
                          const n = stat.length <= 0 ? 0 : ((stat_, status_) => {
                            switch (status_) {
                              case ExecutionStatus.Provisioning: return stat_.numberOfProvisioning;
                              case ExecutionStatus.Pending: return stat_.numberOfPending;
                              case ExecutionStatus.InProgress: return stat_.numberOfInProgress;
                              case ExecutionStatus.Success: return stat_.numberOfSuccess;
                              case ExecutionStatus.Fail: return stat_.numberOfFail;
                              case ExecutionStatus.Canceled: return stat_.numberOfCanceled;
                              default: return 0;
                            }
                          })(stat[0], status);
                          return <Stack direction="column" key={status}>
                            <Typography variant="body2">{executionStatusToStringTranslated(status, t)}</Typography>
                            <Typography variant="body2">{n.toString()}</Typography>
                          </Stack>;
                        })
                      }
                    </Stack>
                  </Grid>
                </CardContent>
                <CardActions>
                  <Button size="large" onClick={() => {
                    setEditingServiceModel({ ...serviceModel });
                    setOpenEditingServiceModelFormDialog(true);
                    setEditServiceModelDialogTs(Date.now());
                  }}>{t("admin_service_model.service_model.edit_button", "Edit")}</Button>
                  {
                    serviceModel.enabled ? <Button size="large" color="error" onClick={async () => {
                      const { err } = await setEnablingServiceModel(serviceModel.id, false);
                      if (err) {
                        await Swal.fire({
                          title: t("admin_service_model.service_model.error_failed_to_disable_service_model", "Failed to disable service model"),
                          text: err.errMsg,
                          icon: "error",
                        })
                        return;
                      }
                      await Swal.fire({
                        title: t("success", "Success"),
                        text: t("admin_service_model.service_model.disable_success", "Service model has been disabled"),
                        icon: "success",
                      });
                      reloadServiceModels();
                    }}>{t("admin_service_model.service_model.disable_button", "Disable")}</Button> :
                      <Button size="large" color="success" onClick={async () => {
                        const { err } = await setEnablingServiceModel(serviceModel.id, true);
                        if (err) {
                          await Swal.fire({
                            title: t("admin_service_model.service_model.error_failed_to_enable_service_model", "Failed to enable service model"),
                            text: err.errMsg,
                            icon: "error",
                          })
                          return;
                        }
                        await Swal.fire({
                          title: t("success", "Success"),
                          text: t("admin_service_model.service_model.enable_success", "Service model has been enabled"),
                          icon: "success",
                        });
                        reloadServiceModels();
                      }}>{t("admin_service_model.service_model.enable_button", "Enable")}</Button>
                  }
                </CardActions>
              </Card>
            </Grid>
          ))
        }
      </Grid>

      <EditServiceModelFormDialog
        ts={editServiceModelDialogTs}
        serviceModel={editingServiceModel}
        openEditServiceModelFormDialog={openEditingServiceModelFormDialog}
        handleEditServiceModelFormDialogClose={async () => {
          await reloadServiceModels();
          reloadExecutionStats(serviceModels.map(s => s.id));
          setOpenEditingServiceModelFormDialog(false);
        }} />


      <Typography variant="h5" sx={{ mb: 5 }}>
        {t("admin_service_model.enable_disable_mps_cloud.header", "Enable/Disable MPS on Cloud")}
      </Typography>

      <Typography variant="body1" sx={{ mb: 5 }}>
        {t("admin_service_model.enable_disable_mps_cloud.detail", "Once enabled, the system autonomously fetches pending executions, send their input to the MPS Cloud server, and automatically collects the solution files from the MPS server.")}
      </Typography>

      <Grid container spacing={3} sx={{ mb: 5 }}>
        <Grid xs={12} sm={6}>
          {
            isJobQueueEnabled ? <Button variant="contained" color="error" onClick={async () => {
              // call api -> api.setJobQueueEnabled
              const { err } = await setJobQueueEnabled(false);
              if (err) {
                await Swal.fire({
                  title: t("admin_service_model.service_model.error_failed_to_disable_mps_could", "Failed to disable"),
                  text: err.errMsg,
                  icon: "error",
                })
                return;
              }
              await Swal.fire({
                title: t("success", "Success"),
                text: t("admin_service_model.service_model.disable_mps_could_success", "Job has been disabled"),
                icon: "success",
              });
              setIsJobQueueEnabled(false);
            }}>{t("admin_service_model.enable_disable_mps_cloud.disable_button", "Disable")}</Button> : <Button variant="contained" color="success" onClick={async () => {
              // call api -> api.setJobQueueEnabled
              const { err } = await setJobQueueEnabled(true);
              if (err) {
                await Swal.fire({
                  title: t("admin_service_model.service_model.error_failed_to_enable_mps_could", "Failed to enable"),
                  text: err.errMsg,
                  icon: "error",
                })
                return;
              }
              await Swal.fire({
                title: t("success", "Success"),
                text: t("admin_service_model.service_model.enable_mps_could_success", "Job has been enabled"),
                icon: "success",
              });
              setIsJobQueueEnabled(true);
            }}>{t("admin_service_model.enable_disable_mps_cloud.enable_button", "Enable")}</Button>
          }
        </Grid>
      </Grid>


      <Typography variant="h5" sx={{ mb: 5 }}>
        {t("admin_service_model.manually_run_job.header", "Manually run Job")}
      </Typography>

      <Typography variant="body1" sx={{ mb: 5 }}>
        {t("admin_service_model.manually_run_job.detail", "Initiate the manual execution of the job to fetch and process pending tasks, bypassing the usual queue interval.")}
      </Typography>

      <Grid container spacing={3} sx={{ mb: 5 }}>
        <Grid xs={12} sm={6}>
          <LoadingButton loading={processing} size="medium" variant="contained" onClick={() => {
            (async () => {
              setProcessing(true);
              const { err } = await processServiceModelExecutionQueue();
              setProcessing(false);
              if (err) {
                await Swal.fire({
                  title: t("admin_service_model.manually_run_job.error_failed_to_execute", "Failed to execute"),
                  text: err.errMsg,
                  icon: "error",
                });
              }
            })();
          }}>{t("admin_service_model.manually_run_job.button_run", "Run")}
          </LoadingButton>
        </Grid>
      </Grid>


      <Typography variant="h5" sx={{ mb: 5 }}>
        {t("admin_service_model.download-mps-request-collection.header", "Download MPS Request collection")}
      </Typography>

      <Typography variant="body1" sx={{ mb: 5 }}>
        {t("admin_service_model.download-mps-request-collection.detail", "Please provide the URL to download MPS Request collection")}
      </Typography>

      <Grid container spacing={3} sx={{ mb: 5 }}>
        <Grid xs={12}>
          <TextField value={downloadMPSRequestCollectionURL} fullWidth onChange={e => setDownloadMPSRequestCollectionURL(e.target.value)} />
        </Grid>
        <Grid xs={12} sm={6}>
          <LoadingButton loading={processing} size="medium" variant="contained" onClick={() => {
            (async () => {
              if (downloadMPSRequestCollectionURL.length === 0) {
                await Swal.fire({
                  title: t("admin_service_model.download-mps-request-collection.error_failed_to_download", "Failed to download"),
                  text: t("admin_service_model.download-mps-request-collection.error_desciption_url_required", "URL is required"),
                  icon: "error",
                });
                return;
              }
              const { err, rawResultInJSON } = await listCollectionOfMPSRequests(downloadMPSRequestCollectionURL);
              if (err) {
                await Swal.fire({
                  title: t("admin_service_model.download-mps-request-collection.error_failed_to_download", "Failed to download"),
                  text: err.errMsg,
                });
                return;
              }
              const blob = new Blob([rawResultInJSON], { type: "text/plain;charset=utf-8" });
              FileSaver.saveAs(blob, "mps-request-collection.json");
              localStorage.setItem("admin_service_model.download-mps-request-collection.last-download-url", downloadMPSRequestCollectionURL);
            })();
          }}>{t("admin_service_model.download-mps-request-collection.download_button", "Download")}
          </LoadingButton>
        </Grid>
      </Grid>

      <Typography variant="h5" sx={{ mb: 5 }}>
        {t("admin_service_model.azure_config.header", "Azure configuration")}
      </Typography>
      <Grid xs={12} md={6} >
        <Card sx={{ mb: 3 }}>
          <CardContent>
            <Stack direction="row" alignItems="center">
              <Iconify icon="ph:circle-fill" sx={{ color: applicationGatewayOperationState === "running" ? "#00e676" : "gray", width: 16, height: 16, mr: 1 }} />
              <Typography variant="h6" color="text.secondary">
                {t("admin_service_model.application_gateway.header", "Application Gateway")}
              </Typography>
            </Stack>
            <Typography variant="body2" sx={{ mb: 1.5 }} color="text.secondary">
              {t("admin_service_model.application_gateway.current_operational_state", "Current Operational state:")} {applicationGatewayOperationState}
            </Typography>
          </CardContent>
          <CardActions>
            <LoadingButton size="medium" variant="outlined" color="primary" loading={processing} onClick={async () => {
              setProcessing(true);
              try {
                await reloadAppGwState();
              } catch (e) {
                console.error("failed to reload app gw state");
              } finally {
                setProcessing(false);
              }
            }}>{t("admin_service_model.service_model.refresh_application_gateway_state", "Refresh")}</LoadingButton>
            <Divider orientation="vertical" variant="middle" flexItem />
            <LoadingButton size="medium" variant="outlined" color="success" loading={processing} onClick={async () => {
              setProcessing(true);
              try {
                await startApplicationGateway();
                await reloadAppGwState();
              } catch (e) {
                console.error("failed to start app gw state");
              } finally {
                setProcessing(false);
              }
            }}>{t("admin_service_model.service_model.start_application_gateway", "Start")}</LoadingButton>
            <LoadingButton size="medium" variant="outlined" color="warning" loading={processing} onClick={async () => {
              setProcessing(true);
              try {
                await stopApplicationGateway();
                await reloadAppGwState();
              } catch (e) {
                console.error("failed to stop app gw state");
              } finally {
                setProcessing(false);
              }
            }}>{t("admin_service_model.service_model.stop_application_gateway", "Stop")}</LoadingButton>
          </CardActions>
        </Card>
      </Grid>

      <Typography variant="body" sx={{ mb: 5 }}>
        {t("admin_service_model.azure_config.description", "You place your Azure configuration in this field. It will be encrypted and stored in the database.")}
      </Typography>

      <TextField fullWidth multiline rows={8} value={azureConfigText} sx={{ mt: 3, mb: 5 }} onChange={e => setAzureConfigText(e.target.value)} />

      <Grid container spacing={3} sx={{ mb: 5 }}>
        <Grid xs={12} sm={6}>
          <LoadingButton loading={processing} size="medium" variant="contained" onClick={() => {

            (async () => {
              let cfg = null;
              try {
                cfg = JSON.parse(azureConfigText);
              } catch (e) {
                await Swal.fire({
                  title: t("admin_service_model.azure_config.error_invalid_json", "Invalid JSON"),
                  text: e.message,
                  icon: "error",
                });
                return;
              }

              setProcessing(true);
              const eck = randomAsciiString(32);
              const { err } = await saveAzureConfig(cfg, eck);
              setProcessing(false);
              if (err) {
                await Swal.fire({
                  title: t("admin_service_model.azure_config.error_failed_to_update", "Failed to update"),
                  text: err.errMsg,
                  icon: "error",
                });
                return;
              }

              await Swal.fire({
                title: t("success", "Success"),
                text: t("admin_service_model.azure_config.successfully_updated", "Successfully updated"),
                icon: "success",
              })

            })();

          }}>{t("admin_service_model.azure_config.update_config_button", "Update configuration")}
          </LoadingButton>
        </Grid>
      </Grid>

    </Container >
  );
}

ServiceModelView.propTypes = {

}


const CreateServiceModelFormDialog = ({ openCreateServiceModelFormDialog, handleCreateServiceModelFormDialogClose, ts }) => {
  const { t } = useTranslation();
  const [isProgressing, setIsProgressing] = useState(false);
  const [serviceModel, setServiceModel] = useState(new ServiceModel());
  const [validationRuleSetsInJSON, setValidationRuleSetsInJSON] = useState("");
  const [pythonCode, setPythonCode] = useState("");

  useEffect(() => {
    setIsProgressing(false);
    setValidationRuleSetsInJSON("");
    setPythonCode("");
  }, [ts]);

  const handleSubmit = async () => {
    let validateRuleSets = {};
    try {
      validateRuleSets = JSON.parse(validationRuleSetsInJSON);
    } catch (err) {
      await Swal.fire({
        title: t("admin_service_model.create_form.error_invalid_validation_rule", "Invalid Validation rule"),
        text: err.message,
        icon: "error",
      });
      return;
    }

    setIsProgressing(true);

    // let newServiceModel = null;
    {
      const { err } = await createServiceModel({
        ...serviceModel,
        validateRuleSets,
        pythonCodeForGenerateOutput: encodeToBase64(pythonCode),
      });
      if (err) {
        setIsProgressing(false);
        await Swal.fire({
          title: t("admin_service_model.create_form.error_failed_to_create", "Failed to create service model"),
          text: err.errMsg,
          icon: "error",
        });
        return;
      }
      // newServiceModel = newServiceModel_;
    }

    await Swal.fire({
      title: t("admin_service_model.create_form.success_title", "Success"),
      text: t("admin_service_model.create_form.success_body", "Service model has been successfully created"),
      icon: "success",
    })

    handleCreateServiceModelFormDialogClose();
  }

  return <Dialog open={openCreateServiceModelFormDialog}>
    <DialogTitle>{t("admin_service_model.create_form.title", "New Service Model")} New Service model</DialogTitle>
    <DialogContent>
      <Grid container spacing={3}>
        <Grid xs={12}>
          <TextField
            label={t("admin_service_model.create_form.field_name", "Name")}
            value={serviceModel.name}
            onChange={(e) => setServiceModel({ ...serviceModel, name: e.target.value })}
            fullWidth
          />
        </Grid>
        <Grid xs={12}>
          <TextField
            id="service_model_form_detail"
            label={t("admin_service_model.create_form.field_detail", "Detail")}
            value={serviceModel.detail}
            onChange={(e) => setServiceModel({ ...serviceModel, detail: e.target.value })}
            fullWidth
          />
        </Grid>
        <Grid xs={12}>
          <TextField
            inputProps={{}}
            label={t("admin_service_model.create_form.field_credit_cost", "Credit Cost")}
            variant="outlined"
            type="number"
            size="small"
            value={serviceModel.creditCost > 0 ? serviceModel.creditCost.toString() : "0"}
            onChange={(e) => {
              const creditCost = e.target.value.length > 0 ? parseInt(e.target.value, 10) : 0;
              setServiceModel({ ...serviceModel, creditCost });
            }}
          />
        </Grid>


        <Grid xs={12}>
          <TextField
            label={t("admin_service_model.create_form.field_mps_api_endpoint", "MPS API endpoint")}
            variant="outlined"
            type="text"
            size="small"
            fullWidth
            value={serviceModel.mpsAPIEndpoint}
            onChange={(e) => setServiceModel({ ...serviceModel, mpsAPIEndpoint: e.target.value })}
          />
        </Grid>

        <Grid xs={12}>
          <TextField
            label={t("admin_service_model.create_form.field_mps_fetch_result_api_endpoint", "Fetch result API endpoint")}
            variant="outlined"
            type="text"
            size="small"
            fullWidth
            value={serviceModel.mpsFetchResultAPIEndpoint}
            onChange={(e) => setServiceModel({ ...serviceModel, mpsFetchResultAPIEndpoint: e.target.value })}
          />
        </Grid>

        <Grid xs={12}>
          <TextField
            label={t("admin_service_model.create_form.field_mps_get_request_info_api_endpoint", "Get request info API endpoint")}
            variant="outlined"
            type="text"
            size="small"
            fullWidth
            value={serviceModel.mpsGetRequestInfoAPIEndpoint}
            onChange={(e) => setServiceModel({ ...serviceModel, mpsGetRequestInfoAPIEndpoint: e.target.value })}
          />
        </Grid>

        <Grid xs={12}>
          <TextField
            label={t("admin_service_model.create_form.field_mps_delete_request_api_endpoint", "Delete request API endpoint")}
            variant="outlined"
            type="text"
            size="small"
            fullWidth
            value={serviceModel.mpsDeleteRequestAPIEndpoint}
            onChange={(e) => setServiceModel({ ...serviceModel, mpsDeleteRequestAPIEndpoint: e.target.value })}
          />
        </Grid>

        <Grid xs={12} container sx={{ mt: 2 }} spacing={1}>
          <Grid xs={12}>
            <Typography variant="caption">{t("admin_service_model.create_form.field_validation_rules_caption", "Input the validation rule or import it directly from a file.")}</Typography>
          </Grid>
          <Grid xs={12} sx={{ mt: 1 }}>
            <TextField
              id="service_model_form_validation_rules"
              label={t("admin_service_model.create_form.field_validation_rules", "Validation rules")}
              value={validationRuleSetsInJSON}
              onChange={(e) => setValidationRuleSetsInJSON(e.target.value)}
              fullWidth
              multiline
              rows={4}
            />
          </Grid>
          <Grid xs={12}>
            <label htmlFor="upload-validation-json-file">
              <Button variant="text" component="span">
                {t("admin_service_model.create_form.button_browse", "Browse")}
              </Button>
              <input
                id="upload-validation-json-file"
                hidden
                accept=".json"
                type="file"
                onChange={async (event) => {
                  if (event.target.files.length === 0) return;
                  const file_ = event.target.files[0];
                  const content = await file_.text();
                  setValidationRuleSetsInJSON(content);
                }} />
            </label>
          </Grid>
        </Grid>

        <Grid xs={12} container sx={{ mt: 2 }} spacing={1}>
          <Grid xs={12}>
            <Typography variant="caption">{t("admin_service_model.create_form.field_python_code_caption", "Input the python code or import it directly from a file.")}</Typography>
          </Grid>
          <Grid xs={12} sx={{ mt: 1 }}>
            <TextField
              id="service_model_form_python_code"
              label={t("admin_service_model.create_form.field_python_code", "Python code for solution exporting")}
              value={pythonCode}
              onChange={(e) => setPythonCode(e.target.value)}
              fullWidth
              multiline
              rows={4}
            />
          </Grid>
          <Grid xs={12}>
            <label htmlFor="upload-python-code-file">
              <Button variant="text" component="span">
                {t("admin_service_model.create_form.browse_button", "Browse")}
              </Button>
              <input
                id="upload-python-code-file"
                hidden
                accept=".py"
                type="file"
                onChange={async (event) => {
                  if (event.target.files.length === 0) return;
                  const file_ = event.target.files[0];
                  const content = await file_.text();
                  setPythonCode(content);
                }} />
            </label>
          </Grid>

          <Grid xs={12}>
            <TextField
              label={t("admin_service_model.create_form.field_more_info_url", "More info URL")}
              variant="outlined"
              type="text"
              size="small"
              fullWidth
              value={serviceModel.moreInfoURL}
              onChange={(e) => setServiceModel({ ...serviceModel, moreInfoURL: e.target.value })}
            />
          </Grid>
        </Grid>

      </Grid>
    </DialogContent>
    <DialogActions>
      <LoadingButton loading={isProgressing}
        type="Create"
        variant="contained"
        color="primary"
        onClick={() => handleSubmit()}>
        {t("admin_service_model.create_form.submit_button", "Create")}
      </LoadingButton>
      <LoadingButton variant="outlined" onClick={() => handleCreateServiceModelFormDialogClose("cancel")}>{t("admin_service_model.create_form.cancel_button", "Cancel")}</LoadingButton>
    </DialogActions>
  </Dialog >;
}

CreateServiceModelFormDialog.propTypes = {
  openCreateServiceModelFormDialog: PropTypes.bool,
  handleCreateServiceModelFormDialogClose: PropTypes.func,
  ts: PropTypes.number,
}


const EditServiceModelFormDialog = ({ serviceModel, openEditServiceModelFormDialog, handleEditServiceModelFormDialogClose, ts }) => {
  useEffect(() => {
    setIsProgressing(false);
    if (serviceModel) {
      setEditingServiceModel({ ...serviceModel });
      setValidationRuleSetsInJSON(JSON.stringify(serviceModel.validateRuleSets, null, 2));
      const pythonCode_ = decodeFromBase64(serviceModel.pythonCodeForGenerateOutput);
      setPythonCode(pythonCode_);
    }
  }, [ts, serviceModel]);

  const { t } = useTranslation();
  const [isProgressing, setIsProgressing] = useState(false);

  if (!serviceModel) {
    serviceModel = new ServiceModel();
  }
  const [editingServiceModel, setEditingServiceModel] = useState({ ...serviceModel });
  const [validationRuleSetsInJSON, setValidationRuleSetsInJSON] = useState(JSON.stringify(serviceModel.validateRuleSets, null, 2));
  const [pythonCode, setPythonCode] = useState("")
  const handleSubmit = async () => {
    let validateRuleSets = {};
    try {
      validateRuleSets = JSON.parse(validationRuleSetsInJSON);
    } catch (err) {
      await Swal.fire({
        title: t("admin_service_model.edit_form.error_invalid_validation_rule", "Invalid Validation rule"),
        text: err.message,
        icon: "error",
      });
      return;
    }

    setIsProgressing(true);
    // let updatedServiceModel = null;
    {
      const { err } = await updateServiceModel(serviceModel.id, {
        ...editingServiceModel,
        validateRuleSets,
        pythonCodeForGenerateOutput: encodeToBase64(pythonCode)
      });
      if (err) {
        setIsProgressing(false);
        await Swal.fire({
          title: t("admin_service_model.edit_form.error_failed_to_update", "Failed to update service model"),
          text: err.errMsg,
          icon: "error",
        });
        return;
      }
      // updatedServiceModel = updatedServiceModel_;
    }

    await Swal.fire({
      title: t("admin_service_model.edit_form.success_title", "Success"),
      text: t("admin_service_model.edit_form.success_body", "Service model has been successfully updated"),
      icon: "success",
      confirmButtonText: t("ok", "Ok"),
    })

    handleEditServiceModelFormDialogClose();
  }

  return <Dialog open={openEditServiceModelFormDialog}>
    <DialogTitle>{t("admin_service_model.edit_form.title", "Edit Service model")} (#{editingServiceModel.id})</DialogTitle>
    <DialogContent>
      <Grid container spacing={3}>
        <Grid xs={12}>
          <TextField
            label={t("admin_service_model.edit_form.field_name", "Name")}
            value={editingServiceModel.name}
            onChange={(e) => setEditingServiceModel({ ...editingServiceModel, name: e.target.value })}
            fullWidth
          />
        </Grid>
        <Grid xs={12}>
          <TextField
            id="service_model_form_detail"
            label={t("admin_service_model.edit_form.field_detail", "Detail")}
            value={editingServiceModel.detail}
            onChange={(e) => setEditingServiceModel({ ...editingServiceModel, detail: e.target.value })}
            fullWidth
          />
        </Grid>
        <Grid xs={12}>
          <TextField
            inputProps={{}}
            label={t("admin_service_model.edit_form.field_credit_cost", "Credit Cost")}
            variant="outlined"
            type="number"
            size="small"
            value={editingServiceModel.creditCost > 0 ? editingServiceModel.creditCost.toString() : "0"}
            onChange={(e) => {
              const creditCost = e.target.value.length > 0 ? parseInt(e.target.value, 10) : 0;
              setEditingServiceModel({ ...editingServiceModel, creditCost });
            }}
          />
        </Grid>

        <Grid xs={12}>
          <TextField
            label={t("admin_service_model.edit_form.field_mps_api_endpoint", "MPS API endpoint")}
            variant="outlined"
            type="text"
            size="small"
            fullWidth
            value={editingServiceModel.mpsAPIEndpoint}
            onChange={(e) => setEditingServiceModel({ ...editingServiceModel, mpsAPIEndpoint: e.target.value })}
          />
        </Grid>

        <Grid xs={12}>
          <TextField
            label={t("admin_service_model.edit_form.field_mps_fetch_result_api_endpoint", "Fetch result API endpoint")}
            variant="outlined"
            type="text"
            size="small"
            fullWidth
            value={editingServiceModel.mpsFetchResultAPIEndpoint}
            onChange={(e) => setEditingServiceModel({ ...editingServiceModel, mpsFetchResultAPIEndpoint: e.target.value })}
          />
        </Grid>

        <Grid xs={12}>
          <TextField
            label={t("admin_service_model.edit_form.field_mps_get_request_info_api_endpoint", "Get request info API endpoint")}
            variant="outlined"
            type="text"
            size="small"
            fullWidth
            value={editingServiceModel.mpsGetRequestInfoAPIEndpoint}
            onChange={(e) => setEditingServiceModel({ ...editingServiceModel, mpsGetRequestInfoAPIEndpoint: e.target.value })}
          />
        </Grid>

        <Grid xs={12}>
          <TextField
            label={t("admin_service_model.edit_form.field_mps_delete_request_api_endpoint", "Delete request API endpoint")}
            variant="outlined"
            type="text"
            size="small"
            fullWidth
            value={editingServiceModel.mpsDeleteRequestAPIEndpoint}
            onChange={(e) => setEditingServiceModel({ ...editingServiceModel, mpsDeleteRequestAPIEndpoint: e.target.value })}
          />
        </Grid>

        <Grid xs={12} container sx={{ mt: 2 }} spacing={1}>
          <Grid xs={12}>
            <Typography variant="caption">{t("admin_service_model.edit_form.field_validation_rules_caption", "Input the validation rule or import it directly from a file.")}</Typography>
          </Grid>
          <Grid xs={12} sx={{ mt: 1 }}>
            <TextField
              id="service_model_form_validation_rules"
              label={t("admin_service_model.edit_form.field_validation_rules", "Validation rules")}
              value={validationRuleSetsInJSON}
              onChange={(e) => setValidationRuleSetsInJSON(e.target.value)}
              fullWidth
              multiline
              rows={4}
            />
          </Grid>
          <Grid xs={12}>
            <label htmlFor="upload-validation-json-file">
              <Button variant="text" component="span">
                {t("admin_service_model.edit_form.button_browse", "Browse")}
              </Button>
              <input
                id="upload-validation-json-file"
                hidden
                accept=".json"
                type="file"
                onChange={async (event) => {
                  if (event.target.files.length === 0) return;
                  const file_ = event.target.files[0];
                  const content = await file_.text();
                  setValidationRuleSetsInJSON(content);
                }} />
            </label>
          </Grid>
        </Grid>

        <Grid xs={12} container sx={{ mt: 2 }} spacing={1}>
          <Grid xs={12}>
            <Typography variant="caption">{t("admin_service_model.edit_form.field_python_code_caption", "Input the python code or import it directly from a file.")}</Typography>
          </Grid>
          <Grid xs={12} sx={{ mt: 1 }}>
            <TextField
              id="service_model_form_python_code"
              label={t("admin_service_model.edit_form.field_python_code", "Python code for solution exporting")}
              value={pythonCode}
              onChange={(e) => {
                setPythonCode(e.target.value);
                console.log(e.target.value);
              }}
              fullWidth
              multiline
              rows={4}
            />
          </Grid>
          <Grid xs={12}>
            <label htmlFor="upload-python-code-file">
              <Button variant="text" component="span">
                {t("admin_service_model.edit_form.browse_button", "Browse")}
              </Button>
              <input
                id="upload-python-code-file"
                hidden
                accept=".py"
                type="file"
                onChange={async (event) => {
                  if (event.target.files.length === 0) return;
                  const file_ = event.target.files[0];
                  const content = await file_.text();
                  setPythonCode(content);
                }} />
            </label>
          </Grid>

          <Grid xs={12}>
            <TextField
              label={t("admin_service_model.create_form.field_more_info_url", "More info URL")}
              variant="outlined"
              type="text"
              size="small"
              fullWidth
              value={editingServiceModel.moreInfoURL}
              onChange={(e) => setEditingServiceModel({ ...editingServiceModel, moreInfoURL: e.target.value })}
            />
          </Grid>
        </Grid>

      </Grid>
    </DialogContent>
    <DialogActions>
      <LoadingButton loading={isProgressing}
        type="button"
        variant="contained"
        color="primary"
        onClick={() => handleSubmit()}>
        {t("admin_service_model.edit_form.update_button", "Update")}
      </LoadingButton>
      <LoadingButton variant="outlined" onClick={() => handleEditServiceModelFormDialogClose("cancel")}>{t("admin_service_model.edit_form.cancel_button", "Cancel")}</LoadingButton>
    </DialogActions>
  </Dialog >;
}

EditServiceModelFormDialog.propTypes = {
  serviceModel: PropTypes.object,
  openEditServiceModelFormDialog: PropTypes.bool,
  handleEditServiceModelFormDialogClose: PropTypes.func,
  ts: PropTypes.number,
}

// Function to encode Unicode string to base64
function encodeToBase64(str) {
  return btoa(unescape(encodeURIComponent(str)));
}

// Function to decode base64 to Unicode string
function decodeFromBase64(base64) {
  return decodeURIComponent(escape(atob(base64)));
}
