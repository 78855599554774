import { useTheme } from "@emotion/react";
import { LoadingButton } from "@mui/lab";
import { Avatar, Button, Card, CardContent, CardHeader, FormControl, TextField, FormLabel, FormHelperText } from "@mui/material";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Pagination from '@mui/material/Pagination';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import FileSaver from "file-saver";
import moment from "moment";
import PropTypes from 'prop-types';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";

import { downloadServiceExecutionInputFileAsJSON, getFileEntityByFileID, listAvailableServiceModels, manualSubmitSolutionFileForExecution, searchServiceExecutions } from "src/utils/api";

import { pageTopMargin } from "src/layouts/dashboard/config-layout";
import { ExecutionStatus, ExecutionStatusList, executionStatusToStringTranslated } from "src/model/service-model-execution";

import Iconify from "src/components/iconify";
import SectionHeader from "src/components/section-header/header";

export default function ServiceModelExecutionsView() {
  const { t } = useTranslation();

  const [currentFilter, setCurrentFilter] = useState({});
  const [isSearching, setIsSearching] = useState(false);
  const [serviceModels, setServiceModels] = useState([]);
  const [executions, setExecutions] = useState([]);
  const [numberOfTotalExecutions, setNumberOfTotalExecutions] = useState(0);
  const [profiles, setProfiles] = useState([]);
  const [page, setPage] = useState(0);
  const [numberOfItemsPerPage, setNumberOfItemsPerPage] = useState(10);

  const search = async (filter, page_ = 1) => {
    setIsSearching(true);
    if (filter.serviceModelID && filter.serviceModelID.length > 0) {
      filter.serviceModelIDs = [filter.serviceModelID];
    }
    if (filter.executionCreatedAtRangeFrom) {
      filter = {
        ...filter,
        executionCreatedAtRangeFrom: moment(filter.executionCreatedAtRangeFrom).startOf("day"),
      };
    }
    if (filter.executionCreatedAtRangeTo) {
      filter = {
        ...filter,
        executionCreatedAtRangeTo: moment(filter.executionCreatedAtRangeTo).endOf("day"),
      };
    }
    const { executions: executions_, numberOfTotalExecutions: numberOfTotalExecutions_, profiles: profiles_, err } = await searchServiceExecutions({
      ...filter,
      offset: numberOfItemsPerPage * (page_ - 1),
      limit: numberOfItemsPerPage,
    });
    if (err) {
      setIsSearching(false);
      return;
    }

    setExecutions(executions_);
    setNumberOfTotalExecutions(numberOfTotalExecutions_);
    setProfiles([...profiles_]);
    setPage(page_);
    setIsSearching(false);
  }

  const downloadFile = async (fileID_) => {
    const { fileEntity, err } = await getFileEntityByFileID(fileID_);
    if (err) {
      await Swal.fire({
        title: t("admin_service_model_executions.error_failed_to_download_file", "Failed to download file"),
        text: err.errMsg,
        icon: "error",
      });
      return;
    }
    const openingURL = `${fileEntity.downloadURL}`;
    console.log("opening url:", openingURL, "...");
    const link = document.createElement("a");
    link.href = openingURL;
    link.type = "application/vnd.ms-excel";
    link.click();
  }

  const downloadInputFileAsJSON = async (serviceModelID, executionID) => {
    const { jsonContent, err } = await downloadServiceExecutionInputFileAsJSON(serviceModelID, executionID);
    if (err) {
      await Swal.fire({
        title: t("admin_service_model_executions.error_filed_to_download_json_file", "Failed to download JSON file"),
        text: err.errMsg,
        icon: "error",
      });
      return;
    }

    // create file in browser
    const fileName = "input-file-json";
    const blob = new Blob([jsonContent], { type: "application/json" });
    const href = URL.createObjectURL(blob);

    // create "a" HTLM element with href to file
    const link = document.createElement("a");
    link.href = href;
    link.download = `${fileName}.json`;
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }

  const executionStatusToIcon = (s) => {
    switch (s) {
      case ExecutionStatus.Provisioning: return "carbon:ibm-z-cloud-provisioning";
      case ExecutionStatus.Pending: return "ic:outline-pending";
      case ExecutionStatus.InProgress: return "carbon:in-progress";
      case ExecutionStatus.Success: return "mdi:success-bold";
      case ExecutionStatus.Fail: return "mdi:sms-failed";
      case ExecutionStatus.Canceled: return "mdi:cancel-bold";
      default: return ``;
    }
  }

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  }

  const doExecutionHaveFailure = (execution) => {
    if (execution.status === ExecutionStatus.Fail) {
      return true;
    }
    if (execution.postRequestFailureMessages && execution.postRequestFailureMessages.length > 0) {
      return true;
    }
    return false;
  }
  const executionFailureBlob = (execution) => {
    let failureReasons = [];

    if (execution.postRequestFailureMessages && execution.postRequestFailureMessages.length > 0) {
      failureReasons = [
        ...failureReasons,
        ...execution.postRequestFailureMessages,
      ];
    }

    if (execution.failureReason) {
      failureReasons.push(execution.failureReason);
    }

    const blobData = failureReasons.length > 0 ? [failureReasons.join("\n----\n")] : [];
    return new Blob(blobData, { type: "text/plain;charset=utf-8" });
  }

  useEffect(() => {
    (async () => {
      const { serviceModels: serviceModels_, err } = await listAvailableServiceModels();
      if (err) return;
      setServiceModels(serviceModels_);
      search({});
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container maxWidth="xl" sx={{ mt: pageTopMargin }}>

      <SectionHeader>
        <Typography variant="h5">
          {t("admin_service_model_executions.header", "Executions")}
        </Typography>
      </SectionHeader>

      <Typography variant="h5" sx={{ mb: 5, mt: 5 }}>
        {t("admin_service_model_executions.history.header", "History")}
      </Typography>

      <Box>
        <FilterControl
          serviceModels={serviceModels}
          isSearching={isSearching}
          onSubmit={(filter) => {
            setCurrentFilter({ ...filter });
            search(filter);
          }} />
      </Box>

      <Grid sx={{ mt: 5, mb: 2 }} container justifyContent="flex-end">
        <Pagination
          page={page}
          onChange={(_, page_) => { if (page !== page_) { setPage(page_); search(currentFilter, page_); } }}
          count={Math.ceil(numberOfTotalExecutions / numberOfItemsPerPage)}
          variant="outlined"
          shape="rounded" />
      </Grid>

      <Grid container spacing={3}>
        {
          executions.map(execution => (
            <Grid xs={12} key={execution.id}>
              <Card>
                <CardHeader
                  avatar={
                    <Avatar sx={{ bgcolor: "orangered" }} aria-label="recipe">
                      <Iconify icon={executionStatusToIcon(execution.status)} />
                    </Avatar>
                  }
                  title={`${t(`service_model.${execution.serviceModelID}.name`, serviceModels.filter(e => e.id === execution.serviceModelID).map(e => e.name)[0])} #${execution.id} `}
                  subheader={
                    <Stack direction="column" spacing={0.5}>
                      <Stack direction="row" spacing={1}>
                        <Typography variant="body2">{`${executionStatusToStringTranslated(execution.status, t)}`}</Typography>
                        <Typography variant="body2">{`${moment(execution.updatedAt).format("YYYY-MM-DD HH:mm:ss")}`}</Typography>
                      </Stack>
                    </Stack>}
                />
                <CardContent>
                  <Container>
                    <Stack direction="column" spacing={1}>
                      <Stack direction="row" alignItems="center">
                        <Typography variant="body" fontWeight={700}>MPS Request ID</Typography>
                        <IconButton onClick={() => copyToClipboard(execution.mpsRequestID)}><Iconify icon="lucide:copy" /></IconButton>
                      </Stack>
                      <Stack direction="row">
                        <Typography variant="body">{`${execution.mpsRequestID}`}</Typography>
                      </Stack>
                    </Stack>
                    {
                      (() => {
                        const profiles_ = profiles.filter(p => p.userID === execution.userID);
                        if (profiles_.length === 0) return null;
                        const profile = profiles_[0];
                        const submittedBy = `${profile.email} (${profile.userID})`;
                        return <Stack direction="column" spacing={1}>
                          <Stack direction="row" alignItems="center">
                            <Typography variant="body" fontWeight={700}>{t("admin_service_model_executions.execution.submitted_by_user", "Submitted by User")}</Typography>
                            <IconButton onClick={() => copyToClipboard(submittedBy)}><Iconify icon="lucide:copy" /></IconButton>
                          </Stack>
                          <Stack direction="row">
                            <Typography variant="body">{submittedBy}</Typography>
                          </Stack>
                        </Stack>
                      })()
                    }
                  </Container>
                  <Container sx={{ mt: 4 }}>
                    {
                      !execution.isInputFileValid ?
                        <Stack direction="row" spacing={1} alignItems="center" sx={{ mb: 1 }}>
                          <Iconify icon="ic:round-warning" color="red" />
                          <Typography variant="body2" color="text.secondary" gutterBottom>
                            {t("admin_service_model_executions.execution.invalid_input_file", "Invalid Input file")}
                          </Typography>
                        </Stack> : null
                    }
                    <Stack direction="row" spacing={2}>
                      {
                        (execution.inputFileIDs && execution.inputFileIDs.length > 0) ?
                          <>
                            <Button variant="text"
                              onClick={() => downloadFile(execution.inputFileIDs[0])}>
                              {t("admin_service_model_executions.execution.download_input_file_button", "Download Input file (.xlsx)")}
                            </Button>
                            <Button variant="text" color="secondary"
                              onClick={() => downloadInputFileAsJSON(execution.serviceModelID, execution.id)}>
                              {t("admin_service_model_executions.execution.download_input_file_json_button", "Download Input file (.json)")}
                            </Button>
                          </>
                          : null
                      }
                      {
                        (execution.resultFileIDs && execution.resultFileIDs.length > 0) ?
                          <Button variant="text" onClick={() => {
                            downloadFile(execution.resultFileIDs[0]);
                          }}>
                            {t("admin_service_model_executions.execution.download_output_file_button", "Download Output file (.xlsx)")}
                          </Button> : null
                      }
                      {
                        (execution.manualUploadResultFileIDs && execution.manualUploadResultFileIDs.length > 0) ?
                          <Button variant="text" onClick={() => {
                            downloadFile(execution.manualUploadResultFileIDs[0]);
                          }}>
                            {t("admin_service_model_executions.execution.download_output_file_manual_button", "Download Output file (Manual)")}
                          </Button> : null
                      }
                      {
                        (execution.solutionFileIDs && execution.solutionFileIDs.length > 0) ?
                          <Button variant="text" onClick={() => {
                            downloadFile(execution.solutionFileIDs[0]);
                          }}>
                            {t("admin_service_model_executions.execution.download_solution_file_button", "Download Solution file (.xlsx)")}
                          </Button> : null
                      }
                      {/* {
                        (execution.status === ExecutionStatus.Fail) ?
                          <Button variant="text" color="primary" onClick={() => {
                            const blob = new Blob([execution.failureReason], { type: "text/plain;charset=utf-8" });
                            FileSaver.saveAs(blob, `${execution.id}-failure.txt`);
                          }}>
                            {t("admin_service_model_executions.execution.download_failure_info_button", "Download Failure info")}
                          </Button> : null
                      } */}
                      {
                        (doExecutionHaveFailure(execution)) ?
                          <Button variant="text" color="primary" onClick={() => {
                            const blob = executionFailureBlob(execution)
                            FileSaver.saveAs(blob, `${execution.id}-failure.txt`);
                          }}>
                            {t("admin_service_model_executions.execution.download_failure_info_button", "Download Failure info")}
                          </Button> : null
                      }
                      {
                        (execution.status !== ExecutionStatus.Provisioning) ?
                          <label htmlFor={`upload-input-file-${execution.id}`}>
                            <Button variant="contained" component="span" color="success">
                              {t("admin_service_model_executions.execution.submit_button", "Submit solution file")}
                            </Button>
                            <input
                              id={`upload-input-file-${execution.id}`}
                              hidden
                              accept=".xlsx"
                              type="file"
                              onChange={async (event) => {
                                console.log("submit solution file:", execution.id);
                                if (event.target.files.length === 0) return;
                                const file_ = event.target.files[0];

                                const confirmResult = await Swal.fire({
                                  title: t("admin_service_model_executions.execution.confirm_uploading_title", "Upload confirm?"),
                                  text: t("admin_service_model_executions.execution.confirm_uploading_body", `Are you sure you want to manually upload the solution file?`),
                                  icon: "warning",
                                  showCancelButton: true,
                                  confirmButtonText: t("admin_service_model_executions.execution.confirm_button", "Confirm"),
                                  cancelButtonText: t("admin_service_model_executions.execution.cancel_button", "Cancel"),
                                });
                                if (!confirmResult.isConfirmed) {
                                  return;
                                }

                                const { err: uploadErr } = await manualSubmitSolutionFileForExecution(execution.id, file_);
                                if (uploadErr) {
                                  await Swal.fire({
                                    title: t("admin_service_model_executions.execution.submit_solution_file_failed", "Upload failed"),
                                    text: uploadErr.errMsg,
                                    icon: "error",
                                  });
                                  search(currentFilter);
                                  return;
                                }

                                Swal.fire({
                                  title: t("admin_service_model_executions.execution.submit_solution_file_success", "Upload success"),
                                  icon: "success",
                                });

                                search(currentFilter);

                                event.target.value = "";
                              }}
                              onClick={(event) => {
                                // this is required to allow onChange is triggered if user choose the same file again
                                event.target.value = null
                              }}
                            />
                          </label> : null
                      }
                    </Stack>
                  </Container>
                </CardContent>
              </Card>
            </Grid>
          ))
        }
      </Grid>

    </Container >
  );
}

ServiceModelExecutionsView.propTypes = {

}

function FilterControl({ serviceModels, isSearching, onSubmit }) {
  const theme = useTheme();
  const { t } = useTranslation();
  const blankFilter = {
    executionID: null,
    serviceModelID: "",
    userName: "",
    executionStartedAt: null,
    executionCreatedAtRangeFrom: null,
    executionCreatedAtRangeTo: null,

    executionStatuses: [],
  };
  const [filter, setFilter] = useState({
    ...blankFilter,
  });

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return <Grid container spacing={2}>
    <Grid xs={12}>
      <Typography variant="body2">
        {t("admin_service_model_executions.history.filter.enter_execution_id", "Enter Execution ID")}
      </Typography>
    </Grid>

    <Grid xs={12} sm={6}>
      <TextField
        label={t("admin_service_model_executions.history.filter.field_execution_id", "Execution ID")}
        value={filter.executionID ?? ""}
        onChange={(e) => setFilter({ ...filter, executionID: e.target.value })}
        fullWidth
      />
    </Grid>

    {/* <Grid xs={12}>
    <Typography variant="body2">
      {t("admin_service_model_executions.history.filter.or_filter_by", "Or Filter by ...")}
    </Typography>
  </Grid> */}

    <Grid xs={12} sm={6}>
      <FormControl fullWidth>
        <InputLabel id="filter-service-model-select-label">{t("admin_service_model_executions.history.filter.service_model", "Service Model")}</InputLabel>
        <Select
          id="service-model-select"
          value={filter.serviceModelID}
          labelId="filter-service-model-select-label"
          label={t("admin_service_model_executions.history.filter.service_model", "Service Model")}
          onChange={(e) => {
            setFilter({ ...filter, serviceModelID: e.target.value });
          }}
        >
          {
            serviceModels.map(s =>
              <MenuItem key={s.id} value={s.id}>
                {s.name}
              </MenuItem>)
          }
        </Select>
      </FormControl>
    </Grid>

    <Grid xs={12} sm={6}>
      <TextField
        label={t("admin_service_model_executions.history.filter.user_name", "User name")}
        value={filter.userName}
        onChange={(e) => setFilter({ ...filter, userName: e.target.value })}
        fullWidth
      />
    </Grid>

    <Grid xs={12} sm={6}>
      <TextField
        label={t("admin_service_model_executions.history.filter.email_address", "Email address")}
        value={filter.emailAddress}
        onChange={(e) => setFilter({ ...filter, emailAddress: e.target.value })}
        fullWidth
      />
    </Grid>

    <Grid xs={12} sm={6}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker label={t("admin_service_model_executions.history.filter.created_from", "Created date (from)")} sx={{ width: "100%" }}
          value={filter.executionCreatedAtRangeFrom}
          onChange={(e) => {
            setFilter({ ...filter, executionCreatedAtRangeFrom: e.toDate() });
          }} />
      </LocalizationProvider>
    </Grid>

    <Grid xs={12} sm={6}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker label={t("admin_service_model_executions.history.filter.created_to", "Created date (to)")} sx={{ width: "100%" }}
          value={filter.executionCreatedAtRangeTo}
          onChange={(e) => {
            setFilter({ ...filter, executionCreatedAtRangeTo: e.toDate() });
          }} />
      </LocalizationProvider>
    </Grid>

    <Grid xs={12} sm={6}>
      <FormControl fullWidth>
        <InputLabel id="filter-execution-status-select-label">{t("admin_service_model_executions.history.filter.execution_status", "Status")}</InputLabel>
        <Select
          multiple
          value={filter.executionStatuses}
          onChange={(e) => {
            const { target: { value } } = e;
            const v = typeof value === 'string' ? value.split(',').map(s => parseInt(s, 10)) : value;
            setFilter({ ...filter, executionStatuses: v });
          }}
          sx={{ width: "100%" }}
          input={<OutlinedInput label="Status" />}
          MenuProps={MenuProps}
          label={t("admin_service_model_executions.history.filter.execution_status", "Status")}
          labelId="filter-execution-status-select-label"
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <em>{t("admin_service_model_executions.history.filter.statuses_option", "statuses")}</em>;
            }
            return selected.map(s => executionStatusToStringTranslated(s, t)).join(', ');
          }}
        >
          <MenuItem disabled value="">
            <em>{t("admin_service_model_executions.history.filter.statuses_option", "statuses")}</em>
          </MenuItem>
          {ExecutionStatusList.map((status) => (
            <MenuItem
              key={status}
              value={status}
              style={((status_) => ({
                fontWeight: filter.executionStatuses.indexOf(status_) === -1 ?
                  theme.typography.fontWeightRegular :
                  theme.typography.fontWeightMedium
              }))(status)}
            >
              {executionStatusToStringTranslated(status, t)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>

    <Grid xs={12}>
      <Stack direction="row" spacing={2}>
        <LoadingButton variant="contained" loading={isSearching} onClick={() => onSubmit({ ...filter })}>
          {t("admin_service_model_executions.history.search_button", "Search")}
        </LoadingButton>
        {/* <Button variant="contained" onClick={() => setSearchParams({ ...filter })}>
        {t("admin_service_model_executions.history.search_button", "Search")}
      </Button> */}
        <Button variant="outlined" color="secondary" onClick={() => setFilter({ ...blankFilter })}>
          {t("admin_service_model_executions.history.clear_filter_button", "Clear")}
        </Button>
      </Stack>
    </Grid>
  </Grid>;
}

FilterControl.propTypes = {
  serviceModels: PropTypes.array,
  isSearching: PropTypes.bool,
  onSubmit: PropTypes.func,
}