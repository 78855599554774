import Swal from "sweetalert2";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Stack from '@mui/material/Stack';
import { LoadingButton } from "@mui/lab";
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import { addOrUpdateAppLocalization } from "src/utils/api";

import Iconify from "src/components/iconify";

const LocalizationEditorDialog = ({ localization, isOpen, onClose }) => {
  const { t } = useTranslation();
  const [isProgressing, setIsProgressing] = useState(false);
  const [editingLocalization, setEditingLocalization] = useState({ language: "", iconifyName: "", languageCode: "" });
  const [resourceInJSON, setResourceInJSON] = useState("{}");
  const [isResourceInJSONValid, setIsResourceInJSONValid] = useState(true);

  useEffect(() => {
    setIsProgressing(false);
    const {
      id,
      languageCode,
      language,
      iconifyName,
      resource,
    } = localization;
    setEditingLocalization({
      id,
      languageCode,
      language,
      iconifyName,
    });

    let resourceJSONFormatted = "{}";
    let isJSONValid = true;
    try {
      resourceJSONFormatted = JSON.stringify(JSON.parse(resource), null, 2);
    } catch (err) {
      isJSONValid = false;
    }
    setResourceInJSON(resourceJSONFormatted);
    setIsResourceInJSONValid(isJSONValid);
  }, [localization]);

  const handleSubmit = async (localization_, resourceInJSON_) => {
    try {
      JSON.parse(resourceInJSON_);
    } catch (err) {
      await Swal.fire({
        title: t("admin_localization.dialog.warning.invalid_resource_in_json_format", "Invalid Resource content in JSON format"),
        text: err.message,
        icon: "error",
        confirmButtonText: t("ok", "Ok"),
      });
      return;
    }

    setIsProgressing(true);

    const { err } = await addOrUpdateAppLocalization(localization_.languageCode, {
      ...localization_,
      resource: resourceInJSON_,
    });
    setIsProgressing(false);
    if (err) {
      await Swal.fire({
        title: t("admin_localization.dialog.warning.failed_to_update_resource_of_localization", "Failed to update resource of localization"),
        text: err.errMsg,
        icon: "error",
        confirmButtonText: t("ok", "Ok"),
      });
      return;
    }

    await Swal.fire({
      title: t("admin_localization.dialog.edit_success", "Success to update resource of localization"),
      icon: "success",
      confirmButtonText: t("ok", "Ok"),
    })

    onClose("done");
  }

  return <Dialog open={isOpen}>
    <DialogTitle>{editingLocalization.id ?
      t("admin_localization.dialog.edit.title", "Edit Localization") :
      t("admin_localization.dialog.create.title", "Add Localization")}
    </DialogTitle>
    <DialogContent>
      <Grid container spacing={3}>
        <Grid xs={12}>
          <TextField
            label={t("admin_localization.dialog.form.language_code", "Language code")}
            value={editingLocalization.languageCode}
            onChange={(e) => setEditingLocalization({ ...editingLocalization, languageCode: e.target.value })}
            fullWidth
          />
        </Grid>
        <Grid xs={12}>
          <TextField
            label={t("admin_localization.dialog.form.language", "Language")}
            value={editingLocalization.language}
            onChange={(e) => setEditingLocalization({ ...editingLocalization, language: e.target.value })}
            fullWidth
          />
        </Grid>
        <Grid xs={12}>
          <Stack direction="row" alignItems="stretch">
            <TextField
              label={t("admin_localization.dialog.form.icon", "Icon")}
              value={editingLocalization.iconifyName}
              onChange={(e) => setEditingLocalization({ ...editingLocalization, iconifyName: e.target.value })}
              fullWidth
            />
            <Iconify icon={editingLocalization.iconifyName} width={32} sx={{ ml: 1 }} />
          </Stack>
          <a href="https://icon-sets.iconify.design/" target="_blank" rel="noopener noreferrer">
            <Typography variant="caption">
              {t("admin_localization.dialog.form.icon", "Find icon on Iconify")}
            </Typography>
          </a>
        </Grid>
        <Grid xs={12}>
          <TextField
            error={!isResourceInJSONValid}
            id="localization_form_resource"
            label={t("admin_localization.dialog.form.resource", "Resource")}
            value={resourceInJSON}
            onChange={(e) => {
              let isJSONValid = true;
              try {
                JSON.parse(e.target.value);
              } catch (err) {
                isJSONValid = false;
              }
              setIsResourceInJSONValid(isJSONValid);
              setResourceInJSON(e.target.value);
            }}
            fullWidth
            multiline
            rows={10}
          />
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <LoadingButton loading={isProgressing}
        type="Create"
        variant="contained"
        color="primary"
        onClick={() => handleSubmit(editingLocalization, resourceInJSON)}>
        {
          editingLocalization.id ?
            t("admin_localization.dialog.form.update_button", "Update") :
            t("admin_localization.dialog.form.create_button", "Create")
        }
      </LoadingButton>
      <LoadingButton variant="outlined" onClick={() => {
        onClose("user_cancel");
      }}>{t("admin_localization.dialog.form.cancel_button", "Cancel")}</LoadingButton>
    </DialogActions>
  </Dialog >;
}

LocalizationEditorDialog.propTypes = {
  localization: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
}

export default LocalizationEditorDialog