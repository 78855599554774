import Swal from 'sweetalert2';
import { useState } from 'react';
import { useTranslation } from "react-i18next";

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import { alpha, useTheme } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';

import { useRouter } from 'src/routes/hooks';
import { resolveRoute } from 'src/routes/route-resolver';

import { storeSessionToken } from 'src/utils/utilities';
import { GetResponseError, syncPreferredLanguage, signupWithEmailAndPassword } from 'src/utils/api';

import { bgGradient } from 'src/theme/css';

import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export default function SignupView() {
    const theme = useTheme();
    const router = useRouter();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [isSigningUp, setIsSigningUp] = useState(false);

    const { t } = useTranslation();

    const handleClick = async () => {
        setIsSigningUp(true);
        const resp = await signupWithEmailAndPassword(email, password);
        setIsSigningUp(false);

        const err = GetResponseError(resp)
        if (err) {
            await Swal.fire({
                title: t("signup.failed_to_signup_title", "Failed to sign up"),
                text: err.errMsg,
                icon: 'error',
            })
            return;
        }

        await Swal.fire({
            title: t("signup.success_title"),
            text: err,
            icon: 'success',
        })

        const { sessionToken } = resp.data.data;
        storeSessionToken(sessionToken);

        // sync preferred language once after signed up success
        syncPreferredLanguage();

        router.replace('/');
    };

    const renderSignupForm = (
        <>
            <Stack spacing={3} marginTop={4}>
                <TextField name="email" label={t("signup.form.field_email", "Email address")}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)} />

                <TextField
                    name="password"
                    label={t("signup.form.field_password", "Password")}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Stack>

            <PasswordPolicy />

            <LoadingButton
                sx={{ mt: 3 }}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                color="inherit"
                onClick={handleClick}
                loading={isSigningUp}
            >
                {t("signup.form.register_button", "Register")}
            </LoadingButton>
        </>
    );

    return (
        <Box
            sx={{
                ...bgGradient({
                    color: alpha(theme.palette.background.default, 0.9),
                    imgUrl: '/assets/background/overlay_4.jpg',
                }),
                height: 1,
            }}>

            <Stack alignItems="center" justifyContent="center" sx={{ height: 1 }}>
                <Card
                    sx={{
                        p: 5,
                        width: 1,
                        maxWidth: 420,
                    }}
                >
                    <Typography variant="h4">{t("signup.form.header", "Register")}</Typography>

                    {renderSignupForm}

                    <Typography variant="body2" sx={{ mt: 2, mb: 5 }}>
                        {t("signup.form.already_have_an_account", "Already have an account?")}
                        <Link variant="subtitle2" sx={{ ml: 0.5 }} href={resolveRoute("login")} >
                            {t("signup.form.login_text_button", "Log in")}
                        </Link>
                    </Typography>

                </Card>
            </Stack>
        </Box>
    );
}

export function PasswordPolicy() {
    const { t } = useTranslation();
    return <Stack mt={1} ml={1}>
        {/* <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}> */}
        <Typography variant="caption">{t("force_reset_password.policy.atleast_8_characters_long", "At least 8 characters long")}</Typography>
        {/* </Box> */}
        {/* <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}> */}
        <Typography variant="caption">{t("force_reset_password.policy.contains_at_least_one_lowercase_letter", "Contains at least one uppercase letter")}</Typography>
        {/* </Box> */}

        {/* <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}> */}
        <Typography variant="caption">{t("force_reset_password.policy.contains_at_least_one_uppercase_letter", "Contains at least one digit")}</Typography>
        {/* </Box> */}

        {/* <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}> */}
        <Typography variant="caption">{t("force_reset_password.policy.contains_at_least_one_special_character", "Contains at least one special character (*[@$!%*?&])")}</Typography>
        {/* </Box> */}
    </Stack>
}