import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

import Box from '@mui/material/Box';

import { getUserProfile } from 'src/utils/api';

import Nav from './nav';
import Main from './main';
// import Header from './header';

// ----------------------------------------------------------------------

export default function DashboardLayout({ children }) {
  const [openNav, setOpenNav] = useState(false);
  const [userProfile, setUserProfile] = useState(null);

  useEffect(() => {
    (async () => {
      const { userProfile: userProfile_, err } = await getUserProfile()
      if (err) {
        return;
      }
      setUserProfile(userProfile_);
    })()
  }, []);

  return (
    <>
      {/* <Header userProfile={userProfile} onOpenNav={() => setOpenNav(true)} navIsOpen={openNav} /> */}

      <Box
        sx={{
          minHeight: 1,
          display: 'flex',
          // flexDirection: { xs: 'column', lg: 'row' },
          flexDirection: { xs: 'row', lg: 'row' },
        }}
      >
        <Nav userProfile={userProfile} openNav={openNav} onCloseNav={() => setOpenNav(false)} />

        <Main>{children}</Main>
      </Box>
    </>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.node,
};
