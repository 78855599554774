import Swal from "sweetalert2";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { LoadingButton } from "@mui/lab";
import Pagination from '@mui/material/Pagination';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Unstable_Grid2';
import { Card, Stack, Avatar, Button, Dialog, Container, CardHeader, IconButton, Typography, CardActions, CardContent, DialogTitle, DialogActions, DialogContent } from "@mui/material";
// import { useRouter } from "src/routes/hooks";

import moment from "moment";
import { useState, useEffect } from "react";

import { getUserCredits, getFileEntityByFileID, getServiceExecutionStats, listAvailableServiceModels, listServiceModelExecutions, startServiceModelExecution, cancelServiceModelExecution, createServiceModelExecution, submitServiceModelExecutionInputFile, downloadServiceExecutionInputFileAsJSON } from "src/utils/api";

import { UserProfile } from "src/model/profile";
import { ServiceModel } from "src/model/service-model";
import { pageTopMargin } from "src/layouts/dashboard/config-layout";
import { ExecutionStatus, executionStatusToString, executionStatusToStringTranslated } from "src/model/service-model-execution";

import Iconify from "src/components/iconify";
import SectionHeader from "src/components/section-header/header";

const DEBUG = false;

export default function ServiceModelView({ userProfile }) {
  const { t } = useTranslation();
  // const router = useRouter();
  const [serviceModels, setServiceModels] = useState([]);
  const [serviceModelExecutionStats, setServiceModelExecutionStats] = useState([]);
  const [executions, setExecutions] = useState([]);
  const [numberOfTotalExecutions, setNumberOfTotalExecutions] = useState(0);
  const [selectedServiceModel, setSelectedServiceModel] = useState(null);
  const [editingExecution, setEditingExecution] = useState(null);
  const [credits, setCredits] = useState(null);

  const [createExecutionFormDialogTs, setCreateExecutionFormDialogTs] = useState(Date.now());
  const [openCreateExecutionFormDialog, setOpenCreateExecutionFormDialog] = useState(false);
  const [openEditExecutionFormDialog, setOpenEditExecutionFormDialog] = useState(false);

  const [page, setPage] = useState(0);
  const [numberOfItemsPerPage, setNumberOfItemsPerPage] = useState(10);

  useEffect(() => {
    (async () => {
      const { serviceModels: serviceModels_, err } = await listAvailableServiceModels();
      if (err) return;
      setServiceModels(serviceModels_);
      reloadExecutions();

      // reload stats ...
      reloadExecutionStats(serviceModels_.map(s => s.id));

      // reload credits ...
      reloadCredits();

    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCreateExecutionFormDialogClose = () => {
    setOpenCreateExecutionFormDialog(false);
    reloadExecutions();
    reloadExecutionStats(serviceModels.map(s => s.id));
    reloadCredits();
  };

  const handleEditExecutionFormDialogClose = () => {
    setOpenEditExecutionFormDialog(false);
    reloadExecutions();
    reloadExecutionStats(serviceModels.map(s => s.id));
  };

  const reloadExecutions = async (page_ = 1) => {
    const { executions: executions_, numberOfTotalExecutions: numberOfTotalExecutions_, err } = await listServiceModelExecutions({
      offset: numberOfItemsPerPage * (page_ - 1),
      limit: numberOfItemsPerPage,
    });
    if (err) {
      return;
    }
    setExecutions(executions_);
    setNumberOfTotalExecutions(numberOfTotalExecutions_);
    setPage(page_);
  };

  const reloadExecutionStats = async (serviceModelIDs) => {
    const { stats, err } = await getServiceExecutionStats(serviceModelIDs);
    if (err) {
      return;
    }
    setServiceModelExecutionStats(stats);
  };

  const reloadCredits = async () => {
    const { credits: credits_, err: err_ } = await getUserCredits();
    if (err_) {
      console.error("failed to get credits:", err_);
      return;
    }
    setCredits(credits_);
  }

  const downloadFile = async (fileID_, downloadFileName) => {
    const { fileEntity, err } = await getFileEntityByFileID(fileID_);
    if (err) {
      await Swal.fire({
        title: t("service_model.error_failed_to_download_file", "Failed to download file"),
        text: err.errMsg,
        icon: "error",
      });
      return;
    }

    const openingURL = `${fileEntity.downloadURL}`;
    console.log(`opening url: ${openingURL} with name: ${fileEntity.name} ...`);
    const link = document.createElement("a");
    link.type = "application/vnd.ms-excel";
    if (downloadFileName && downloadFileName.endsWith(".xlsx")) {
      link.download = downloadFileName;
    }
    link.href = openingURL;
    link.click();
  }

  const downloadInputFileAsJSON = async (serviceModelID, executionID) => {
    const { jsonContent, err } = await downloadServiceExecutionInputFileAsJSON(serviceModelID, executionID);
    if (err) {
      await Swal.fire({
        title: t("service_model.error_failed_to_download_json_file", "Failed to download file"),
        text: err.errMsg,
        icon: "error",
        confirmButtonText: t("ok", "Ok"),
      });
      return;
    }

    // create file in browser
    const fileName = "input-file-json";
    const blob = new Blob([jsonContent], { type: "application/json" });
    const href = URL.createObjectURL(blob);

    // create "a" HTLM element with href to file
    const link = document.createElement("a");
    link.href = href;
    link.download = `${fileName}.json`;
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }

  const executionStatusToIcon = (s) => {
    switch (s) {
      case ExecutionStatus.Provisioning: return "carbon:ibm-z-cloud-provisioning";
      case ExecutionStatus.Pending: return "ic:outline-pending";
      case ExecutionStatus.InProgress: return "carbon:in-progress";
      case ExecutionStatus.Success: return "mdi:success-bold";
      case ExecutionStatus.Fail: return "mdi:success-bold";
      case ExecutionStatus.Canceled: return "mdi:cancel-bold";
      default: return ``;
    }
  }

  const startExecution = async (serviceodelID_, executionID_) => {
    // startServiceModelExecution
    const { err } = await startServiceModelExecution(serviceodelID_, executionID_);
    if (err) {
      await Swal.fire({
        title: t("service_model.error_failed_to_start_execution_title", "Failed to start execution"),
        text: err.errMsg,
        icon: "error",
        confirmButtonText: t("ok", "Ok"),
      });
      return;
    }
    reloadExecutions();
    reloadExecutionStats(serviceModels.map(s => s.id));
  };

  const cancelExecution = async (serviceodelID_, executionID_) => {
    // confirm to cancel
    const { isConfirmed } = await Swal.fire(
      {
        title: t("service_model.confirm_cancel_execution_title", "Cancel Execution"),
        text: t("service_model.confirm_cancel_execution_body", "Are you sure to cancel this execution?"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("service_model.yes_button", "Yes"),
        cancelButtonText: t("service_model.no_button", "No"),
      }
    )
    if (!isConfirmed) {
      return;
    }

    // cancelServiceModelExecution
    const { err } = await cancelServiceModelExecution(serviceodelID_, executionID_);
    if (err) {
      await Swal.fire({
        title: t("service_model.error_failed_to_cancel_execution_title", "Failed to cancel"),
        text: err.errMsg,
        icon: "error",
      });
      return;
    }
    reloadExecutions();
    reloadExecutionStats(serviceModels.map(s => s.id));
    reloadCredits();
  };

  return (
    <Container maxWidth="xl" sx={{ mt: pageTopMargin }}>
      <SectionHeader>
        <Typography variant="h5">
          {t("service_model.available_service", "Available Services")}
        </Typography>
      </SectionHeader>

      <Grid container spacing={3}>
        {
          serviceModels.map(serviceModel => {
            let remaining = 0;
            // let holding = 0;
            if (credits && serviceModel) {
              const credit = credits.filter(c => c.serviceModelID === serviceModel.id)[0];
              if (credit) {
                remaining = credit.purchased - (credit.used + credit.holding);
              }
              // const { holding: holding_ } = credit;
              // holding = holding_;
            }

            return (
              <Grid xs={12} md={6} key={serviceModel.id}>
                <Card>
                  <CardContent>
                    <Typography variant="h4" color="text.secondary" gutterBottom>
                      {t(`service_model.${serviceModel.id}.name`, serviceModel.name)}
                      {/* {serviceModel.name} */}
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 1.5 }} color="text.secondary">
                      {t("service_model.credit_required", "Credit required:")} {serviceModel.creditCost}
                      {
                        remaining > serviceModel.creditCost ?
                          <Typography component="span" variant="body2" color="text.secondary"> {t("service_model.remaining_credit", "Remaining:")} {remaining}</Typography> :
                          <Typography component="span" variant="body2" color="error"> {t("service_model.remaining_credit", "Remaining:")} {remaining}</Typography>
                      }
                    </Typography>
                    <Typography variant="body2">
                      {t(`service_model.${serviceModel.id}.detail`, serviceModel.detail)}
                      {/* {serviceModel.detail} */}
                    </Typography>
                    <Grid container sx={{ mt: 2 }}>
                      <Stack direction="row" spacing={1} divider={<Divider orientation="vertical" flexItem />}>
                        {
                          [
                            ExecutionStatus.Provisioning,
                            ExecutionStatus.Pending,
                            ExecutionStatus.InProgress,
                            ExecutionStatus.Success,
                            ExecutionStatus.Fail,
                            // ExecutionStatus.Canceled,
                          ].map(status => {
                            const stat = serviceModelExecutionStats.filter(s => s.serviceModelID === serviceModel.id);
                            const n = stat.length <= 0 ? 0 : ((stat_, status_) => {
                              switch (status_) {
                                case ExecutionStatus.Provisioning: return stat_.numberOfProvisioning;
                                case ExecutionStatus.Pending: return stat_.numberOfPending;
                                case ExecutionStatus.InProgress: return stat_.numberOfInProgress;
                                case ExecutionStatus.Success: return stat_.numberOfSuccess;
                                case ExecutionStatus.Fail: return stat_.numberOfFail;
                                case ExecutionStatus.Canceled: return stat_.numberOfCanceled;
                                default: return 0;
                              }
                            })(stat[0], status);
                            return <Stack direction="column" key={status}>
                              <Typography variant="body2">{executionStatusToStringTranslated(status, t)}</Typography>
                              <Typography variant="body2">{n.toString()}</Typography>
                            </Stack>;
                          }
                          )
                        }
                      </Stack>
                    </Grid>
                  </CardContent>
                  <CardActions>
                    <Button size="medium" onClick={() => {
                      const credit = credits.filter(c => c.serviceModelID === serviceModel.id)[0];
                      if (credit) {
                        remaining = credit.purchased - (credit.used + credit.holding);
                        if (remaining < serviceModel.creditCost) {
                          Swal.fire({
                            title: t("service_model.error_not_enough_credit_title", "Not enough credit"),
                            text: t("service_model.error_not_enough_credit_text", "Please purchase more credits"),
                            icon: "error",
                          });
                          return;
                        }
                      }
                      setSelectedServiceModel(serviceModel);
                      setCreateExecutionFormDialogTs(Date.now());
                      setOpenCreateExecutionFormDialog(true);
                    }}>{t("service_model.execute_button", "Execute")}</Button>
                    {
                      serviceModel.moreInfoURL && serviceModel.moreInfoURL.length > 0 ?
                        <Button size="medium" onClick={() => {
                          // eslint-disable-next-line no-nested-ternary
                          const url = serviceModel.moreInfoURL.startsWith("https://") ?
                            serviceModel.moreInfoURL :
                            serviceModel.moreInfoURL.startsWith("http://") ?
                              serviceModel.moreInfoURL :
                              `https://${serviceModel.moreInfoURL}`;
                          window.open(url, "_blank")
                        }}>{t("service_model.more_info_button", "More info")}
                        </Button> : null
                    }
                  </CardActions>
                </Card>
              </Grid>
            );
          })
        }
      </Grid>

      <Typography variant="h5" sx={{ mb: 1, mt: 5 }}>
        {t("service_model.execution_history_title", "Execution history")}
      </Typography>

      <Grid sx={{ mt: 2, mb: 2 }} container justifyContent="flex-end">
        <Pagination
          page={page}
          onChange={(_, page_) => { if (page !== page_) { setPage(page_); reloadExecutions(page_); } }}
          count={Math.ceil(numberOfTotalExecutions / numberOfItemsPerPage)}
          variant="outlined"
          shape="rounded" />
      </Grid>

      <Grid container spacing={3}>
        {
          executions.map(execution => (
            <Grid xs={12} key={execution.id}>
              <Card>
                <CardHeader
                  avatar={
                    <Avatar sx={{ bgcolor: "orangered" }} aria-label="recipe">
                      <Iconify icon={executionStatusToIcon(execution.status)} />
                    </Avatar>
                  }
                  // title={`${serviceModels.filter(e => e.id === execution.serviceModelID).map(e => e.name)[0]} #${execution.id} `}
                  title={`${t(`service_model.${execution.serviceModelID}.name`, serviceModels.filter(e => e.id === execution.serviceModelID).map(e => e.name)[0])} #${execution.id} `}
                  subheader={`${executionStatusToStringTranslated(execution.status, t)}... ${moment(execution.updatedAt).format("YYYY-MM-DD HH:mm:ss")}`}
                />
                <CardContent>
                  {
                    !execution.isInputFileValid ?
                      <Stack direction="row" spacing={1} alignItems="center" sx={{ mb: 1 }}>
                        <Iconify icon="ic:round-warning" color="red" />
                        <Typography variant="body2" color="text.secondary" gutterBottom>
                          {t("service_model.execution.invalid_input_file", "Invalid Input file")}
                        </Typography>
                      </Stack> : null
                  }
                  <Stack direction="row" spacing={2}>
                    {
                      (execution.status === ExecutionStatus.Provisioning) ?
                        <Button variant="text" onClick={() => {
                          setEditingExecution(execution);
                          setOpenEditExecutionFormDialog(true);
                        }}>
                          {t("service_model.execution.edit_button", "Edit")}
                        </Button>
                        : null
                    }
                    {
                      (execution.status === ExecutionStatus.Provisioning && execution.isInputFileValid) ?
                        <Button variant="contained" color="success" onClick={() => startExecution(execution.serviceModelID, execution.id)}>
                          {t("service_model.execution.start_button", "Start")}
                        </Button>
                        : null
                    }
                    {
                      (execution.status === ExecutionStatus.Provisioning || execution.status === ExecutionStatus.Pending) ?
                        <Button variant="contained" color="warning" onClick={() => cancelExecution(execution.serviceModelID, execution.id)}>
                          {t("service_model.execution.cancel_button", "Cancel")}
                        </Button>
                        : null
                    }
                    {
                      (execution.inputFileIDs && execution.inputFileIDs.length > 0) ?
                        <>
                          <Button variant="text"
                            onClick={() => downloadFile(execution.inputFileIDs[0], execution.inputFileName)}>
                            {t("service_model.execution.download_input_file_button", "Download Input file")}
                          </Button>
                          {
                            DEBUG ?
                              <Button variant="text" color="secondary"
                                onClick={() => downloadInputFileAsJSON(execution.serviceModelID, execution.id)}>
                                {t("service_model.execution.download_input_file_json_button", "Download Input file (JSON)")}
                              </Button> : null}
                        </>
                        : null
                    }
                    {
                      (execution.manualUploadResultFileIDs && execution.manualUploadResultFileIDs.length > 0) || (execution.resultFileIDs && execution.resultFileIDs.length > 0) ?
                        <Button variant="text" onClick={() => {
                          if (execution.manualUploadResultFileIDs && execution.manualUploadResultFileIDs.length > 0) {
                            downloadFile(execution.manualUploadResultFileIDs[0]);
                          } else if (execution.resultFileIDs && execution.resultFileIDs.length > 0) {
                            downloadFile(execution.resultFileIDs[0]);
                          }
                        }
                        }>
                          {t("service_model.execution.download_output_file_button", "Download Output file")}
                        </Button> : null
                    }

                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          ))
        }
      </Grid>

      <CreateExecutionFormDialog
        serviceModel={selectedServiceModel}
        openCreateExecutionFormDialog={openCreateExecutionFormDialog}
        handleCreateExecutionFormDialogClose={handleCreateExecutionFormDialogClose}
        credits={credits}
        ts={createExecutionFormDialogTs} />

      <EditExecutionFormDialog
        execution={editingExecution}
        openEditExecutionFormDialog={openEditExecutionFormDialog}
        handleEditExecutionFormDialogClose={handleEditExecutionFormDialogClose} />

    </Container>
  );
}

ServiceModelView.propTypes = {
  userProfile: PropTypes.instanceOf(UserProfile),
}

const CreateExecutionFormDialog = ({ serviceModel, userProfile, openCreateExecutionFormDialog, handleCreateExecutionFormDialogClose, credits, ts }) => {
  const { t } = useTranslation();
  const [file, setFile] = useState(null);
  const [isProgressing, setIsProgressing] = useState(false);

  useEffect(() => {
    setFile(null);
    setIsProgressing(false);
  }, [ts]);

  const handleFileUpload = (event) => {
    if (event.target.files.length === 0) return;
    const file_ = event.target.files[0];
    setFile(file_);
  };

  const handleSubmit = async (inputFile) => {
    setIsProgressing(true);
    let execution = null;
    {
      const { execution: execution_, err } = await createServiceModelExecution(serviceModel.id)
      if (err) {
        setIsProgressing(false);
        await Swal.fire({
          title: t("service_model.create_execution_form.error_failed_to_create_service_model_execution", "Failed to create execution"),
          text: err.errMsg,
          icon: "error",
        });
        return;
      }
      execution = execution_;
    }

    {
      const { isFileValid, err } = await submitServiceModelExecutionInputFile(serviceModel.id, execution.id, inputFile);
      if (err) {
        setIsProgressing(false);

        await Swal.fire({
          title: t("service_model.create_execution_form.error_failed_to_upload_service_model_execution_input_file", "Failed to upload execution input file"),
          text: err.errMsg,
          icon: "error",
        });
        return;
      }

      if (!isFileValid) {
        await Swal.fire({
          title: t("service_model.create_execution_form.error_failed_to_service_model_execution_created_but_input_file_upload_failed", "Failed to create execution"),
          text: t("service_model.create_execution_form.error_failed_to_service_model_execution_created_but_input_file_upload_failed_body", "Execution has been created but failed to upload execution input file"),
          icon: "error",
          confirmButtonText: t("ok", "Ok"),
        });
        handleCreateExecutionFormDialogClose();
        return;
      }
      setIsProgressing(false);
    }

    await Swal.fire({
      title: t("service_model.create_execution_form.create_service_model_execution_success", "Success"),
      text: t("service_model.create_execution_form.create_service_model_execution_success_body", "Execution has been successfully created"),
      icon: "success",
      confirmButtonText: t("ok", "Ok"),
    });

    handleCreateExecutionFormDialogClose();
  }

  let remaining = 0;
  let holding = 0;
  if (credits && serviceModel) {
    const credit = credits.filter(c => c.serviceModelID === serviceModel.id)[0];
    if (credit) {
      remaining = credit.purchased - (credit.used + credit.holding);
      const { holding: holding_ } = credit.holding;
      holding = holding_;
    }
  }

  return <Dialog open={openCreateExecutionFormDialog}>
    <DialogTitle>{t("service_model.create_execution_form.title", "Execution")}</DialogTitle>
    <DialogContent>

      <Grid container spacing={3}>
        <Grid xs={12}>
          {t("service_model.create_execution_form.your_remaining_credit", "Your remaining credit:")} {remaining} {holding > 0 ? `(on-hold: ${holding.toString()})` : ""}
        </Grid>
        <Grid xs={12}>
          <Typography variant="body1">{t("service_model.create_execution_form.create_execution_dialog_choose_file", "Please choose an Input file")}</Typography>
          {
            file ?
              <Stack direction="row" alignItems="center">
                <Typography variant="body1" color="green">{file.name}</Typography>
                <IconButton onClick={() => setFile(null)}>
                  <Iconify icon="clarity:remove-solid" color="orange" />
                </IconButton>
              </Stack> : null
          }
          {
            !file ?
              <label htmlFor="upload-input-file">
                <Button variant="contained" component="span" sx={{ mt: 3 }}>
                  {t("service_model.create_execution_form.input_file_browse_button", "Browse")}
                </Button>
                <input
                  id="upload-input-file"
                  hidden
                  accept=".xlsx"
                  type="file"
                  onChange={handleFileUpload}
                />
              </label> : null
          }
          {
            file ?
              <LoadingButton loading={isProgressing}
                type="submit"
                variant="contained"
                color="primary"
                sx={{ mt: 3 }}
                onClick={() => handleSubmit(file)}>
                {t("service_model.create_execution_form.submit_button", "Submit")}
              </LoadingButton> : null}
        </Grid>
      </Grid>

    </DialogContent>
    <DialogActions>
      <Button onClick={() => handleCreateExecutionFormDialogClose("cancel")}>{t("service_model.create_execution_form.cancel_button", "Cancel")}</Button>
    </DialogActions>
  </Dialog >;
}

CreateExecutionFormDialog.propTypes = {
  userProfile: PropTypes.instanceOf(UserProfile),
  serviceModel: PropTypes.instanceOf(ServiceModel),
  openCreateExecutionFormDialog: PropTypes.bool,
  handleCreateExecutionFormDialogClose: PropTypes.func,
  ts: PropTypes.number,
  credits: PropTypes.array,
}

const EditExecutionFormDialog = ({ execution, userProfile, openEditExecutionFormDialog, handleEditExecutionFormDialogClose }) => {
  const { t } = useTranslation();
  const [file, setFile] = useState(null);
  const [isProgressing, setIsProgressing] = useState(false);

  useEffect(() => {
    setIsProgressing(false);
  }, [execution]);

  useEffect(() => {
    if (openEditExecutionFormDialog) {
      setFile(null);
    }
  }, [openEditExecutionFormDialog])

  const handleFileUpload = (event) => {
    if (event.target.files.length === 0) return;
    const file_ = event.target.files[0];
    setFile(file_);
  };

  const handleSubmit = async (inputFile) => {
    setIsProgressing(true);

    {
      const { isFileValid, err } = await submitServiceModelExecutionInputFile(execution.serviceModelID, execution.id, inputFile);
      if (err) {
        setIsProgressing(false);
        await Swal.fire({
          title: t("service_model.edit_execution_form.error_failed_to_upload_service_model_execution_input_file", "Failed to upload execution input file"),
          text: err.errMsg,
          icon: "error",
        });
        return;
      }

      if (!isFileValid) {
        await Swal.fire({
          title: t("service_model.edit_execution_form.error_failed_to_service_model_execution_created_but_input_file_upload_failed", "Invalid Input file"),
          text: t("service_model.edit_execution_form.error_failed_to_service_model_execution_created_but_input_file_upload_failed_body", "You have updated an invlid input file, please download the input file including an errors"),
          icon: "error",
        });
        handleEditExecutionFormDialogClose();
        return;
      }
      setIsProgressing(false);
    }

    await Swal.fire({
      title: t("service_model.edit_execution_form.edit_service_model_execution_success", "Success"),
      text: t("service_model.edit_execution_form.edit_service_model_execution_success_body", "Execution has been successfully updated"),
      icon: "success",
    });

    handleEditExecutionFormDialogClose();
  }

  return <Dialog open={openEditExecutionFormDialog}>
    <DialogTitle>{t("service_model.edit_execution_form.title", "Execution")}</DialogTitle>
    {execution ?
      <DialogContent>
        <Grid container spacing={3}>
          {
            !execution.isInputFileValid ?
              <Grid xs={12}>
                <Stack direction="row" spacing={1} alignItems="center" sx={{ mb: 1 }}>
                  <Iconify icon="ic:round-warning" color="red" />
                  <Typography variant="body2" color="text.secondary" gutterBottom>
                    {t("service_model.edit_execution_form.invalid_input_file", "Invalid Input file")}
                  </Typography>
                </Stack>
              </Grid> : null
          }
          <Grid xs={12}>
            <Typography variant="body1">{t("create_execution_dialog_choose_file", "Please choose an Input file")}</Typography>
            {
              !file ? <>
                <Typography variant="caption" color="GrayText">{t("create_execution_dialog_last_uploaded_file", "Last uploaded file name")}</Typography>
                <Typography variant="body1" color="GrayText">{execution.inputFileName ? execution.inputFileName : `unnamed-file.xlsx`}</Typography>
              </> : null
            }
            {
              file ?
                <Stack direction="row" alignItems="center">
                  <Typography variant="body1" color="green">{file.name}</Typography>
                  <IconButton onClick={() => setFile(null)}>
                    <Iconify icon="clarity:remove-solid" color="orange" />
                  </IconButton>
                </Stack> : null
            }
            {
              !file ?
                <label htmlFor="upload-input-file">
                  <Button variant="contained" component="span" sx={{ mt: 3 }}>
                    {t("service_model.edit_execution_form.input_file_browse_button", "Browse")}
                  </Button>
                  <input
                    id="upload-input-file"
                    hidden
                    accept=".xlsx"
                    type="file"
                    onChange={handleFileUpload}
                  />
                </label> : null}
            {
              file ?
                <LoadingButton loading={isProgressing}
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{ mt: 3 }}
                  onClick={() => handleSubmit(file)}>
                  {t("service_model.edit_execution_form.submit_button", "Submit")}
                </LoadingButton> : null}
          </Grid>
        </Grid>

      </DialogContent>
      : null}
    <DialogActions>
      <Button onClick={() => handleEditExecutionFormDialogClose("cancel")}>{t("service_model.edit_execution_form.cancel_button", "Cancel")}</Button>
    </DialogActions>
  </Dialog >;
}

EditExecutionFormDialog.propTypes = {
  userProfile: PropTypes.instanceOf(UserProfile),
  execution: PropTypes.object,
  openEditExecutionFormDialog: PropTypes.bool,
  handleEditExecutionFormDialogClose: PropTypes.func
}
