import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

// const icon = (name) => (
//   <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
// );

const icon = (name) => (
  <Iconify icon={name} sx={{ width: 1, height: 1 }} />
);

const navConfig = (t) => [
  // {
  //   title: 'overview',
  //   path: '/',
  //   icon: icon('ic_analytics'),
  // },
  {
    title: t("nav.profile", 'profile'),
    path: '/profile',
    icon: icon("mdi:user"),
  },
  {
    title: t("nav.service_model", 'service-model'),
    path: '/service-model',
    icon: icon("fluent-mdl2:table-computed"),
    isDefaultPath: true,
  }
];

export const navConfigAdmin = (t) => [
  {
    title: t("nav.admin.users", "users"),
    path: "/admin/users",
    icon: icon("mdi:user"),
  },
  {
    title: t("nav.admin.service_model", "service-model"),
    path: "/admin/service-model",
    icon: icon("fluent-mdl2:table-computed"),
  },
  {
    title: t("nav.admin.service_model_execution", "service-model-executions"),
    path: "/admin/service-model-executions",
    icon: icon("fluent:history-16-filled"),
  },
  {
    title: t("nav.admin.localization", "localization"),
    path: "/admin/localization",
    icon: icon("material-symbols:language"),
  },
]

export default navConfig;
