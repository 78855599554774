
export function capitalize(s) {
    if (s.length === 0) return s;
    const a = [s.charAt(0).toUpperCase()];
    if (s.length > 1) {
        a.push(s.substring(1));
    }
    return a.join("");
}

export function truncateString(str, n) {
    if (str.length > n) {
        return `${str.substring(0, n)}..."`;
    }
    return str;
}

export function truncateStringMiddle(str, n) {
    if (str.length > n * 2) {
        return `${str.substring(0, n)}...${str.substring(str.length - n)}`;
    }
    return str;
}

export function storeSessionToken(token) {
    localStorage.setItem("session-token", token);
}

export function readSessionToken() {
    return localStorage.getItem("session-token");
}

export function isImpersonated() {
    const t = readSessionToken();
    if (t.length === 0) return false;
    return t.startsWith("IMPERSONATE!");
}

export function clearSessionToken() {
    localStorage.setItem("session-token", "");
}

/**
 * 
 * @param {Date} t 
 */
export function timeSinceInMs(t) {
    const now_ = new Date();
    return now_ - t;
}

export function now() {
    return new Date();
}

export function isMetamaskErrorUserRejected(err) {
    if (err && err.code &&
        (err.code === 4001 || err.code === "ACTION_REJECTED")) {
        console.warn("metamask user rejected");
        return true;
    }
    return false;
}

export function setCurrentLanguage(lang) {
    // for wordpress, we get an active language from the url, so setCurrentLanguage is no-op
    // localStorage.setItem("current_lang", lang); 
}

export function getCurrentLanguage() {
    let lang = "en";

    const currentURL = window.location.href;

    let re = /https:\/\/onshorepower.io\/(.*?)\//;
    if (currentURL.includes("localhost")) {
        re = /http:\/\/localhost:3000\/(.*?)\//;
    }

    const match = re.exec(currentURL);
    if (match && match.length > 1) {
        lang = match[match.length - 1];
    }

    // if the language is #, this might be running from localhost or another web hosting which does not language ("en", ...) in url path
    if (lang === "#" || lang.length === 0) {
        lang = "en";
    }

    return lang;
}

function randomAsciiChar() {
    const index = Math.floor(Math.random() * 62);
    // Generate Number character
    if (index < 10) {
        return String(index);
        // Generate capital letters
    } if (index < 36) {
        return String.fromCharCode(index + 55);
    }
    // Generate small-case letters
    return String.fromCharCode(index + 61);
}

export function randomAsciiString(length) {
    let result = "";
    while (length > 0) {
        result += randomAsciiChar();
        length -= 1;
    }
    return result;
}