import Swal from 'sweetalert2';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import { alpha, useTheme } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';

import { useRouter } from 'src/routes/hooks';
import { resolveRoute } from 'src/routes/route-resolver';

import { storeSessionToken } from 'src/utils/utilities';
import { GetResponseError, syncPreferredLanguage, loginWithEmailAndPassword } from 'src/utils/api';

import { bgGradient } from 'src/theme/css';
import { APICommonErrorIDs } from 'src/constants/error-codes';
import { pageTopMargin } from 'src/layouts/dashboard/config-layout';

import Iconify from 'src/components/iconify';


// ----------------------------------------------------------------------

export default function LoginView() {
  const theme = useTheme();

  const router = useRouter();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);


  const { t } = useTranslation();

  const handleClick = async () => {
    setIsLoggingIn(true);
    const resp = await loginWithEmailAndPassword(email, password);
    setIsLoggingIn(false);

    const err = GetResponseError(resp)
    if (err) {
      let { errMsg } = err;
      switch (err.errID) {
        case APICommonErrorIDs.AccountDoesNotActivate:
          errMsg = t("login.error_account_does_not_activate", "Account does not activate. Please contact support."); break;
        default: break;
      }
      await Swal.fire({
        title: t("login.failed_to_login_title", "Failed to log in"),
        text: errMsg,
        icon: 'error',
      })
      return;
    }

    await Swal.fire({
      title: t("login_success_title", "Success"),
      text: err,
      icon: 'success',
    })

    const { sessionToken } = resp.data.data;
    storeSessionToken(sessionToken);

    // sync preferred language once after logged in success
    syncPreferredLanguage();

    router.replace('/');
  };

  const handleKeyEnterPressed = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13 || e.which === 13 || e.code === 'Enter' || e.code === 'NumpadEnter') {
      handleClick();
    }
  }

  const renderLoginForm = (
    <>
      <Stack spacing={3} marginTop={4}>
        <TextField
          name="email"
          label={t("login.form.field_email_address", "Email address")}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onKeyUp={(e) => handleKeyEnterPressed(e)} />

        <TextField
          name="password"
          label={t("login.form.field_password", "Password")}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyUp={(e) => handleKeyEnterPressed(e)}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <LoadingButton
        sx={{ mt: 3 }}
        loading={isLoggingIn}
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        color="inherit"
        onClick={handleClick}
      >
        {t("login.form.login_button", "Login")}
      </LoadingButton>
    </>
  );

  return (
    <Box
      sx={{
        padding: { xs: 3, md: 5, mt: pageTopMargin },
        ...bgGradient({
          color: alpha(theme.palette.background.default, 0.9),
          imgUrl: '/assets/background/overlay_4.jpg',
        }),
        height: 1,
      }}
    >
      <Stack alignItems="center" justifyContent="center" sx={{ height: 1 }}>
        <Card
          sx={{
            p: 5,
            width: 1,
            maxWidth: 420,
          }}
        >
          {/* <Typography variant="h4">{t("login_page_title_login", "Log in")}</Typography> */}
          <Typography variant="h4">{t("login_page_title_login", "Log in")}</Typography>

          {renderLoginForm}

          <Typography variant="body2" sx={{ mt: 2, mb: 5 }}>
            {t("login.form.text_dont_have_an_account", "Don’t have an account?")}
            <Link variant="subtitle2" sx={{ ml: 0.5 }} href={resolveRoute("signup")}>
              {t("login.form.register_button", "Register")}
            </Link>
          </Typography>

        </Card>
      </Stack>

    </Box>
  );
}
