import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

import { Stack } from '@mui/material';

import { getUserProfile } from 'src/utils/api';

import AccountPopover from 'src/layouts/dashboard/common/account-popover';
import NotificationsPopover from "src/layouts/dashboard/common/notifications-popover";


export default function SectionHeader({ children }) {
    const [userProfile, setUserProfile] = useState(null);
    useEffect(() => {
        (async () => {
            const { userProfile: userProfile_, err } = await getUserProfile()
            if (err) {
                return;
            }
            setUserProfile(userProfile_);
        })();
    }, []);

    return <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        {children}
        <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>
            <NotificationsPopover />
            {/* <LanguagePopover /> */}
            {
                userProfile ? <AccountPopover userProfile={userProfile} /> : null
            }
        </Stack>
    </Stack>;
}

SectionHeader.propTypes = {
    children: PropTypes.node,
};
