import Swal from "sweetalert2";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { MuiTelInput } from "mui-tel-input";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

import { LoadingButton } from "@mui/lab";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { Card, Stack, Button, Avatar, TextField, CardHeader, CardContent, CardActions, InputAdornment } from '@mui/material';

import { useRouter } from "src/routes/hooks";

import { storeSessionToken } from "src/utils/utilities";
import { topup, decreaseCredit, startImpersonate, updateUserProfile, resetPasswordByUser, adminGetUserCredits, resetPasswordByAdmin, adminUpdateUserProfile, adminSetUserActivation, adminListServiceModels, adminListAvailableServiceModels, grantServiceModelPermissionToUser, revokeServiceModelPermissionToUser, deleteUserAndAllData } from "src/utils/api";

import { pageTopMargin } from "src/layouts/dashboard/config-layout";

import Iconify from "src/components/iconify";
import SectionHeader from "src/components/section-header/header";

import { PasswordPolicy } from "src/sections/signup/signup-view";

// ----------------------------------------------------------------------

class UserServiceModelInfo {
  constructor() {
    this.serviceModelID = "";
    this.serviceModelName = "";
    this.granted = false;
    this.credit = {
      purchased: 0,
      used: 0,
      holding: 0,
    };
  }
}

export default function UserProfileView({ userProfile, viewAsAdmin = false }) {
  const { t } = useTranslation();
  const router = useRouter();
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState(userProfile.firstName);
  const [lastName, setLastName] = useState(userProfile.lastName);
  const [companyName, setCompanyName] = useState(userProfile.companyName);
  const [companyAddress, setCompanyAddress] = useState(userProfile.companyAddress);
  const [phoneNumber, setPhoneNumber] = useState(userProfile.phoneNumber);
  const [jobTitleOrRole, setJobTitleOrRole] = useState(userProfile.jobTitleOrRole);
  const [vatNumber, setVatNumber] = useState(userProfile.vatNumber);
  const [isUpdating, setIsUpdating] = useState(false);

  const [oldPassword, setOldPassword] = useState("");
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);

  // @type {UserServiceModelInfo[]} userServiceModelInfos
  const [userServiceModelInfos, setUserServiceModelInfos] = useState([]);
  const [purchasingCreditValues, setPurchasingCreditValues] = useState(new Map());

  useEffect(() => {
    (async () => {
      if (!viewAsAdmin) return

      const { serviceModels: serviceModels_, err: getServiceModelsErr } = await adminListServiceModels();
      const { credits: credits_, err: adminGetUserCreditsErr } = await adminGetUserCredits(userProfile.userID);
      const { serviceModels: availableServiceModels_, err: adminListAvailableServiceModelsErr } = await adminListAvailableServiceModels(userProfile.userID);
      if (getServiceModelsErr) {
        console.log("failed to get service models");
        return;
      }
      if (adminGetUserCreditsErr) {
        console.log("failed to get credits");
        return;
      }
      if (adminListAvailableServiceModelsErr) {
        console.log("failed to get available service models");
        return;
      }

      const l = [];
      serviceModels_.forEach(s => {
        const userServiceModelInfo = new UserServiceModelInfo();
        userServiceModelInfo.serviceModelID = s.id;
        userServiceModelInfo.serviceModelName = s.name;
        userServiceModelInfo.granted = availableServiceModels_.filter(e => e.id === s.id).length > 0;

        const credit = {
          purchased: 0,
          used: 0,
          holding: 0,
        };
        const credits = credits_.filter((c) => c.serviceModelID === s.id);
        if (credits.length > 0) {
          credit.purchased = credits[0].purchased;
          credit.used = credits[0].used;
          credit.holding = credits[0].holding;
        }
        userServiceModelInfo.credit = credit;

        l.push(userServiceModelInfo);
      });

      setUserServiceModelInfos(l);
      setPurchasingCreditValues(new Map([
        ...l.map(e => [e.serviceModelID, 0]),
      ]));

    })();
  }, [userProfile, viewAsAdmin]);

  const handleUpdateProfileSubmit = async () => {
    setIsUpdating(true);

    let err = null;

    if (viewAsAdmin) {
      const { err: err_ } = await adminUpdateUserProfile({
        userID: userProfile.userID,
        firstName,
        lastName,
        companyName,
        companyAddress,
        phoneNumber,
        jobTitleOrRole,
        vatNumber,
      });
      err = err_;
    } else {
      const { err: err_ } = await updateUserProfile({
        firstName,
        lastName,
        companyName,
        companyAddress,
        phoneNumber,
        jobTitleOrRole,
        vatNumber,
      });
      err = err_;
    }
    setIsUpdating(false);

    if (err) {
      Swal.fire({
        title: t("profile.error_failed_to_update_profile_title", "Failed to update"),
        text: err.errMsg,
        icon: "error",
        confirmButtonText: t("ok", "Ok"),
      });
      return;
    }
    Swal.fire({
      title: t("profile.update_profile_success_title", "Updated profile success"),
      icon: "success",
      confirmButtonText: t("ok", "Ok")
    })
  };

  const handleChangePasswordSubmit = async () => {
    setIsUpdating(true);

    let err = null;

    if (viewAsAdmin) {
      const { err: err_ } = await resetPasswordByAdmin({
        userID: userProfile.userID,
        newPassword,
      });
      err = err_;
    } else {
      const { err: err_ } = await resetPasswordByUser({
        oldPassword,
        newPassword,
      });
      err = err_;
    }
    setIsUpdating(false);

    if (err) {
      Swal.fire({
        title: t("profile.error_failed_to_update_profile_title", "Failed to change password"),
        text: err.errMsg,
        icon: "error",
        confirmButtonText: t("ok", "Ok"),
      })
      return;
    }


    await Swal.fire({
      title: t("profile.change_password_success_title", "Password changed sucessfully"),
      text: t("profile.change_password_success_body", "Your session is logged out, we will navigate you back to Log in page."),
      icon: "success",
      confirmButtonText: t("ok", "Ok")
    });

    if (!viewAsAdmin) {
      router.replace('/login');
    }
  };

  const handleUpdateUserActivation = async (activate) => {
    setIsUpdating(true);

    const { err } = await adminSetUserActivation(userProfile.userID, activate);
    setIsUpdating(false);

    if (err) {
      Swal.fire({
        title: t("profile.error_failed_to_update_user_activation", "Failed to update user activation"),
        text: err.errMsg,
        icon: "error",
        confirmButtonText: t("ok", "Ok"),
      })
      return;
    }

    await Swal.fire({
      title: t("profile.update_user_activation_success_title", "Activation updated"),
      text: activate ?
        t("profile.user_has_been_activated", "User has been activated.") :
        t("profile.user_has_been_deactivated", "User has been deactivated"),
      icon: "success",
      confirmButtonText: t("ok", "Ok")
    });
  };

  const handleImpersonation = async (userID) => {
    setIsUpdating(true);

    const { sessionToken, err } = await startImpersonate(userID);
    setIsUpdating(false);

    if (err) {
      Swal.fire({
        title: t("profile.error_failed_to_impersonate", "Failed to impersonate"),
        text: err.errMsg,
        icon: "error",
      })
      return;
    }

    await Swal.fire({
      title: t("profile.impersonate_success_title", "Impersonate success"),
      text:
        t("profile.impersonate_success_body", "The user has been successfully impersonated. You will be logged out and logged in as the impersonated user now"),
      icon: "success",
    });

    storeSessionToken(sessionToken);
    navigate(0);
  };

  const handleDeleteUserAndAllData = async () => {
    const confirmResult = await Swal.fire({
      title: t("profile.form_service_model.confirm_account_deletion_title", "Confirm Account deletion"),
      text: t("profile.form_service_model.confirm_account_deletion_body", `Are you sure you want to delete this account? Please confirm to continue`),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("profile.form_service_model.confirm_account_deletion_button", "Confirm"),
      cancelButtonText: t("profile.form_service_model.cancel_account_deletion_button", "Cancel"),
    });
    if (!confirmResult.isConfirmed) {
      return;
    }

    setIsUpdating(true);

    const { err } = await deleteUserAndAllData(userProfile.userID);
    setIsUpdating(false);

    if (err) {
      Swal.fire({
        title: t("profile.error_failed_to_delete_user", "Failed to delete user"),
        text: err.errMsg,
        icon: "error",
        confirmButtonText: t("ok", "Ok"),
      })
      return;
    }

    await Swal.fire({
      title: t("profile.delete_user_success_title", "User has been deleted"),
      text: t("profile.delete_user_success body", "User and all og its data have been deleted."),
      icon: "success",
      confirmButtonText: t("ok", "Ok")
    });

    navigate("/admin/users", { replace: true });
  };

  return (
    <Container maxWidth="xl" sx={{ mt: pageTopMargin }}>
      <SectionHeader>
        <Typography variant="h5">
          {t("profile.form.header", "Profile")}
        </Typography>
      </SectionHeader>

      <Grid container spacing={3}>
        <Grid xs={12} sm={6}>
          <TextField
            label={t("profile.form.field_user_id", "User ID")}
            value={userProfile.userID}
            fullWidth
            disabled
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <TextField
            label={t("profile.form.field_email", "Email")}
            value={userProfile.email}
            fullWidth
            disabled
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <TextField
            label={t("profile.form.field_first_name", "First name")}
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <TextField
            label={t("profile.form.field_last_name", "Last name")}
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <MuiTelInput
            label={t("profile.form.field_phone_number", "Phone number")}
            value={phoneNumber}
            onChange={(v) => setPhoneNumber(v)}
            fullWidth
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <TextField
            label={t("profile.form.field_job_title_or_role", "Job title or Role")}
            value={jobTitleOrRole}
            onChange={(e) => setJobTitleOrRole(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid xs={12}>
          <TextField
            label={t("profile.form.field_company", "Company")}
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid xs={12}>
          <TextField
            label={t("profile.form.field_company_address", "Company Address")}
            value={companyAddress}
            onChange={(e) => setCompanyAddress(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid xs={12}>
          <TextField
            label={t("profile.form.field_vat_number", "VAT Number")}
            value={vatNumber}
            onChange={(e) => setVatNumber(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid xs={12}>
          <LoadingButton
            sx={{ mt: 1 }}
            loading={isUpdating}
            type="submit"
            variant="contained"
            color="primary"
            onClick={() => handleUpdateProfileSubmit()}
          >
            {t("profile.form.update_button", "Update")}
          </LoadingButton>
        </Grid>
      </Grid>

      <Typography variant="h5" sx={{ mt: 5, mb: 2 }}>
        {t("profile.form_change_password.header", "Change password")}
      </Typography>

      <Grid container spacing={3}>
        <Grid xs={12}>
          <Typography variant="body2">
            {viewAsAdmin ?
              t("profile.form_change_password.header.admin_warning_message", "Changing password will result in a mandatory logout from all active sessions. After successfully changing user password, that user will need to log in to the system again.") :
              t("profile.form_change_password.header.user_warning_message", "Changing your password will result in a mandatory logout from all active sessions. After successfully changing your password, you will need to log in to the system again.")}
          </Typography>
        </Grid>
        {
          viewAsAdmin ? null :
            <Grid xs={12}>
              <TextField
                name="old-password"
                label={t("profile.form_change_password.field_old_password", "Old password")}
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                type={showOldPassword ? 'text' : 'password'}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowOldPassword(!showOldPassword)} edge="end">
                        <Iconify icon={showOldPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
        }
        <Grid xs={12}>
          <TextField
            name="new-password"
            label={t("profile.form_change_password.field_new_password", "New password")}
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            type={showNewPassword ? 'text' : 'password'}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowNewPassword(!showNewPassword)} edge="end">
                    <Iconify icon={showNewPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <PasswordPolicy />
        </Grid>

        <Grid xs={12}>
          <LoadingButton
            sx={{ mt: 1 }}
            loading={isUpdating}
            type="submit"
            variant="contained"
            color="error"
            onClick={() => handleChangePasswordSubmit()}
          >
            {t("profile.form_change_password.reset_password_button", "Reset password")}
          </LoadingButton>
        </Grid>
      </Grid>

      {viewAsAdmin ? <>
        <Typography variant="h5" sx={{ mt: 5, mb: 2 }}>
          {t("profile.form_service_model.header", "Service Models")}
        </Typography>

        <Grid container spacing={2}>
          {
            userServiceModelInfos.map((info, index) => (
              <Grid xs={12} sm={6} key={info.serviceModelID}>
                <Card sx={{ width: '100%' }}>
                  <CardHeader
                    avatar={
                      <Avatar sx={{ bgcolor: info.granted ? 'green' : 'gray' }} aria-label="recipe">
                        <Iconify icon="tdesign:user-checked-1" color="white" />
                      </Avatar>
                    }
                    title={info.serviceModelName}
                    subheader={`ID: ${info.serviceModelID}`}
                  />
                  <CardContent>
                    {/* <Typography variant="h6" color="text.secondary">
                      {t("user_service_model_card_credit_title", "Credit")}
                    </Typography> */}
                    <Stack direction="column" spacing={2} justifyContent="space-between">
                      <Typography variant="body" color="text.primary">
                        {t("profile.form_service_model.remaining_credit", "Remaining credit:")} {info.credit.purchased - (info.credit.used + info.credit.holding)} ({t("profile.form_service_model.on_hold_credit", "On-hold:")}: {info.credit.holding})
                      </Typography>
                      <Stack direction="row" spacing={1}>
                        <TextField
                          inputProps={{}}
                          label={t("profile.form_service_model.field_topup_amount", "Amount")}
                          variant="outlined"
                          type="number"
                          size="small"
                          value={
                            (() => {
                              const v = purchasingCreditValues.has(info.serviceModelID) ? purchasingCreditValues.get(info.serviceModelID).toString() : "0"
                              return v;
                            })()
                          }
                          onChange={(e) => {
                            purchasingCreditValues.set(info.serviceModelID, e.target.value.length > 0 ? parseInt(e.target.value, 10) : 0);
                            setPurchasingCreditValues(new Map(purchasingCreditValues));
                          }}
                        />
                        <Button size="small" variant="outlined" onClick={async () => {
                          const amount = purchasingCreditValues.get(info.serviceModelID);
                          const confirmResult = await Swal.fire({
                            title: t("profile.form_service_model.confirm_topup_title", "Topup Confirm"),
                            text: t("profile.form_service_model.confirm_topup_body", `Are you sure you want to topup ${amount.toString()}?`),
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonText: t("profile.form_service_model.confirm_topup_button", "Confirm"),
                            cancelButtonText: t("profile.form_service_model.cancel_topup_button", "Cancel"),
                          });
                          if (!confirmResult.isConfirmed) {
                            return;
                          }

                          const { credit, err } = await topup(userProfile.userID, info.serviceModelID, amount);
                          if (err) {
                            Swal.fire({
                              title: t("profile.form_service_model.error_topup_fail", "Topup Fail"),
                              text: err.errMsg,
                              icon: "error",
                            });
                            return;
                          }

                          // update display data ...
                          info.credit.purchased = credit.purchased;
                          userServiceModelInfos[index] = info;
                          setUserServiceModelInfos([...userServiceModelInfos]);

                          // reset purchasing amount to zero
                          purchasingCreditValues.set(info.serviceModelID, 0)
                          setPurchasingCreditValues(new Map(purchasingCreditValues));

                          Swal.fire({
                            title: t("profile.form_service_model.topup_success_title", "Topup Success"),
                            text: t("profile.form_service_model.topup_success_body", "Topup success"),
                            icon: "success",
                          });
                        }}>
                          {t("profile.form_service_model.topup_credit_button", "Topup")}
                        </Button>

                        <Button size="small" variant="outlined" color="warning" onClick={async () => {
                          const amount = purchasingCreditValues.get(info.serviceModelID);
                          const confirmResult = await Swal.fire({
                            title: t("profile.form_service_model.confirm_decrease_credit_title", "Decrease credit Confirm"),
                            text: t("profile.form_service_model.confirm_decrease_credit_body", `Are you sure you want to decrease credit by ${amount.toString()}?`),
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonText: t("profile.form_service_model.confirm_decrease_credit_button", "Confirm"),
                            cancelButtonText: t("profile.form_service_model.cancel_decrease_credit_button", "Cancel"),
                          });
                          if (!confirmResult.isConfirmed) {
                            return;
                          }

                          const { credit, err } = await decreaseCredit(userProfile.userID, info.serviceModelID, amount);
                          if (err) {
                            Swal.fire({
                              title: t("profile.form_service_model.error_failed_to_decrease_credit", "Decrease credit fail"),
                              text: err.errMsg,
                              icon: "error",
                            });
                            return;
                          }

                          // update display data ...
                          info.credit.purchased = credit.purchased;
                          userServiceModelInfos[index] = info;
                          setUserServiceModelInfos([...userServiceModelInfos]);

                          // reset purchasing amount to zero
                          purchasingCreditValues.set(info.serviceModelID, 0);
                          setPurchasingCreditValues(new Map(purchasingCreditValues));

                          Swal.fire({
                            title: t("profile.form_service_model.decrease_credit_success_title", "Decrease credit success"),
                            text: t("profile.form_service_model.decrease_credit_success_body", "Decrease credit success"),
                            icon: "success",
                            confirmButtonText: t("ok", "Ok"),
                          });
                        }}>
                          {t("profile.form_service_model.decrease_credit_button", "Decrease")}
                        </Button>
                      </Stack>

                    </Stack>
                  </CardContent>
                  <CardActions>
                    <Button size="small" variant="contained" color={!info.granted ? "success" : "error"} onClick={async (e) => {
                      let grantOrRevokeErr = null;
                      if (info.granted) {
                        const { err_ } = await revokeServiceModelPermissionToUser(userProfile.userID, info.serviceModelID);
                        grantOrRevokeErr = err_;
                      } else {
                        const { err_ } = await grantServiceModelPermissionToUser(userProfile.userID, info.serviceModelID);
                        grantOrRevokeErr = err_;
                      }
                      if (grantOrRevokeErr) {
                        Swal.fire({
                          title: t("profile.form_service_model.grant_or_revoke_fail_title", "Update permission failed"),
                          text: grantOrRevokeErr.errMsg,
                          icon: "error",
                        });
                        return;
                      }

                      userServiceModelInfos[index].granted = !info.granted;
                      setUserServiceModelInfos([...userServiceModelInfos]);
                    }}>
                      {info.granted ?
                        t("profile.form_service_model.revoke_button", "Revoke") :
                        t("profile.form_service_model.grant_button", "Grant")
                      }
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))
          }
        </Grid>
      </> : null}

      {viewAsAdmin ? <>
        <Typography variant="h5" sx={{ mt: 5, mb: 2 }}>
          {t("profile_from_danger_zone", "Danger zone")}
        </Typography>

        <Grid container spacing={3}>
          <Grid xs={12}>
            <Typography variant="body2">
              {t("profile.deactivate_activate_warning_message", "Before you proceed, please make sure that deactivating your account is the action you truly intend to take. If you have any questions or concerns, feel free to contact our support team for assistance.")}
            </Typography>
          </Grid>

          <Grid xs={12}>
            <LoadingButton
              sx={{ mt: 1 }}
              loading={isUpdating}
              type="submit"
              variant="contained"
              color={userProfile.isActive ? "error" : "success"}
              onClick={() => {
                // isActive = true => activate = false
                // isActive = false => activate = true
                const activate = !userProfile.isActive;
                handleUpdateUserActivation(activate);
              }}>
              {
                userProfile.isActive ?
                  t("profile.deactivate_button", "Deactivate") :
                  t("profile.activate_button", "Activate")
              }
            </LoadingButton>
          </Grid>

        </Grid>

        <Grid container spacing={3} sx={{ mt: 3 }}>
          <Grid xs={12}>
            <Typography variant="body2">
              {t("profile.impersonate_warning_message", "You are about to impersonate another user account. Impersonation is a powerful action with important implications. Impersonate only for legitimate and authorized reasons, such as troubleshooting or providing user support. Misuse of this feature may result in account suspension.")}
            </Typography>
          </Grid>

          <Grid xs={12}>
            <LoadingButton
              sx={{ mt: 1 }}
              loading={isUpdating}
              type="submit"
              variant="contained"
              color="primary"
              onClick={() => handleImpersonation(userProfile.userID)}>
              {t("profile.impersonate_button", "Impersonate")}
            </LoadingButton>
          </Grid>
        </Grid>

        <Grid container spacing={3} sx={{ mt: 3 }}>
          <Grid xs={12}>
            <Typography variant="body2">
              {t("profile.delete_account_warning_message", "To delete this account and all of his/her data. Please be caution, this action cannot be undone.")}
            </Typography>
          </Grid>

          <Grid xs={12}>
            <LoadingButton
              sx={{ mt: 1 }}
              loading={isUpdating}
              type="submit"
              variant="contained"
              color="error"
              onClick={() => handleDeleteUserAndAllData(userProfile.userID)}>
              {t("profile.delete_account_button", "Delete this Account")}
            </LoadingButton>
          </Grid>
        </Grid>


      </> : null}
    </Container >
  );
}

// proptypes
UserProfileView.propTypes = {
  userProfile: PropTypes.object,
  viewAsAdmin: PropTypes.bool,
}