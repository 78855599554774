import { XModel } from "./xmodel";

export class ServiceModel extends XModel {
  constructor() {
    super();
    this.name = "";
    this.detail = "";
    this.version = "";

    this.mpsAPIEndpoint = "";
    this.mpsFetchResultAPIEndpoint = "";
    this.mpsGetRequestInfoAPIEndpoint = "";
    this.mpsDeleteRequestAPIEndpoint = "";

    this.pythonCodeForGenerateOutput = "";
    this.enabled = false;
    this.validateRuleSets = [];
    this.creditCost = 1;

    this.moreInfoURL = "";
  }

  static fromObject(obj) {
    const m = new ServiceModel();
    Object.assign(m, obj);
    return m;
  }
}