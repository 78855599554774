import { XModel } from "./xmodel";

export class ServiceModelExecution extends XModel {
  constructor() {
    super();
    this.userID = "";
    this.serviceModelID = "";
    this.status = "";
    this.associatedCreditTransactionID = "";
    this.userCanceledAt = "";
    this.startedAt = "";
    this.finishedAt = "";
    this.failureCode = "";
    this.failureReason = "";

    // @type {string[]} postRequestFailureMessages
    this.postRequestFailureMessages = [];

    this.inputFileIDs = [];
    this.resultFileIDs = [];
    this.solutionFileIDs = [];
    this.manualUploadResultFileIDs = [];

    this.isInputFileValid = "";
    this.expectedMpsResultFileName = "";
  }

  static fromObject(obj) {
    const m = new ServiceModelExecution();
    Object.assign(m, obj);
    return m;
  }
}

export function executionStatusToString(s) {
  switch (s) {
    case ExecutionStatus.Provisioning: return "Provisioning";
    case ExecutionStatus.Pending: return "Pending";
    case ExecutionStatus.InProgress: return "InProgress";
    case ExecutionStatus.Success: return "Success";
    case ExecutionStatus.Fail: return "Fail";
    case ExecutionStatus.Canceled: return "Canceled";
    default: return `Unknown status (${s})`;
  }
}

export function executionStatusToStringTranslated(s, t) {
  if (!t) {
    return executionStatusToString(s);
  }

  switch (s) {
    case ExecutionStatus.Provisioning: return t("execution_status.provisioning", "Provisioning");
    case ExecutionStatus.Pending: return t("execution_status.pending", "Pending");
    case ExecutionStatus.InProgress: return t("execution_status.in_progress", "InProgress");
    case ExecutionStatus.Success: return t("execution_status.success", "Success");
    case ExecutionStatus.Fail: return t("execution_status.fail", "Fail");
    case ExecutionStatus.Canceled: return t("execution_status.canceled", "Canceled");
    default: return `Unknown status (${s})`;
  }
}

export const ExecutionStatus = Object.freeze({
  Provisioning: 1001,
  Pending: 2001,
  InProgress: 3001,
  Success: 8001,
  Fail: 9001,
  Canceled: 9091
});

export const ExecutionStatusList = [
  ExecutionStatus.Provisioning,
  ExecutionStatus.Pending,
  ExecutionStatus.InProgress,
  ExecutionStatus.Success,
  ExecutionStatus.Fail,
  ExecutionStatus.Canceled,
];