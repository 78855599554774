import { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Popover from '@mui/material/Popover';
import { alpha } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import { useRouter } from 'src/routes/hooks';

import { logout, stopImpersonate } from 'src/utils/api';
import { isImpersonated, clearSessionToken, storeSessionToken } from 'src/utils/utilities';


// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  // {
  //   label: 'Overview',
  //   icon: 'eva:home-fill',
  // },
  // {
  //   label: 'Profile',
  //   icon: 'eva:person-fill',
  // },
  // {
  //   label: 'Settings',
  //   icon: 'eva:settings-2-fill',
  // },
];

// ----------------------------------------------------------------------

export default function AccountPopover({ userProfile }) {
  const [open, setOpen] = useState(null);
  const router = useRouter();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogoutAndClose = async () => {
    setOpen(null);
    await logout();
    clearSessionToken();
    router.push('/login');
  };

  const handleStopImpersonateAndClose = async () => {
    setOpen(null);
    const { originalSessionToken, err } = await stopImpersonate();
    if (err) {
      console.log(err);
      return;
    }

    storeSessionToken(originalSessionToken);
    navigate(0);
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          width: 42,
          height: 40,
          background: (theme) => alpha(theme.palette.grey[500], 0.08),
          ...(open && {
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
      >
        <Avatar
          alt={userProfile.firstName}
          sx={{
            width: 32,
            height: 32,
            border: (theme) => `solid 2px ${theme.palette.background.default}`,
            bgcolor: 'primary.main',
          }}
        >
          {userProfile && userProfile.firstName.length > 0 ? userProfile.firstName.charAt(0).toUpperCase() : ""}
        </Avatar>
      </IconButton>

      <Popover
        open={!!open}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1,
            ml: 0.75,
            width: 200,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2 }}>
          <Typography variant="subtitle2" noWrap>
            {userProfile.name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {userProfile.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        {MENU_OPTIONS.map((option) => (
          <MenuItem key={option.label} onClick={handleClose}>
            {option.label}
          </MenuItem>
        ))}

        <Divider sx={{ borderStyle: 'dashed', m: 0 }} />


        {
          isImpersonated() ?
            <MenuItem
              disableRipple
              disableTouchRipple
              onClick={() => handleStopImpersonateAndClose()}
              sx={{ typography: 'body2', color: 'error.main', py: 1.5 }}
            >
              {t("stop_impersonate", "Stop Impersonate")}
            </MenuItem> :
            <MenuItem
              disableRipple
              disableTouchRipple
              onClick={() => handleLogoutAndClose()}
              sx={{ typography: 'body2', color: 'error.main', py: 1.5 }}
            >
              {t("logout", "Logout")}
            </MenuItem>
        }
      </Popover>
    </>
  );
}

// proptypes
AccountPopover.propTypes = {
  userProfile: PropTypes.object,
};