import { useTranslation } from 'react-i18next';

import { UserView } from 'src/sections/admin-users/view';

// ----------------------------------------------------------------------

export default function AdminUsersPage() {
    const { t } = useTranslation();
    return (
        <UserView />
    );
}
