import { useState } from 'react';
import PropTypes from 'prop-types';

import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Popover from '@mui/material/Popover';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import { UserProfile } from 'src/model/profile';

import Iconify from 'src/components/iconify';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function UserTableRow({
  selected,
  handleClick,
  handleEditClick,
  userProfile,
}) {
  const { t } = useTranslation();

  const [open, setOpen] = useState(null);

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = (args) => {
    setOpen(null);
    if (args === "edit") {
      handleEditClick();
    }
  };

  const name = `${userProfile.firstName} ${userProfile.lastName}`;
  const { companyName, email, jobTitleOrRole, isActive } = userProfile;

  return (
    <>
      <TableRow hover tabIndex={-1} role="checkbox" selected={selected}>
        <TableCell padding="checkbox">
          <Checkbox disableRipple checked={selected} onChange={handleClick} />
        </TableCell>

        <TableCell component="th" scope="row" padding="none">
          <Stack direction="row" alignItems="center" spacing={2}>
            <Avatar>{name ? name[0] : ""}</Avatar>
            <Typography variant="subtitle2" noWrap>
              {name}
            </Typography>
          </Stack>
        </TableCell>

        <TableCell>{email}</TableCell>

        <TableCell>{companyName}</TableCell>

        <TableCell>{jobTitleOrRole}</TableCell>

        <TableCell align="center">{isActive ? 'Yes' : 'No'}</TableCell>

        <TableCell align="right">
          <IconButton onClick={handleOpenMenu}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <Popover
        open={!!open}
        anchorEl={open}
        onClose={() => handleCloseMenu()}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: { width: 140 },
        }}
      >
        <MenuItem onClick={() => handleCloseMenu("edit")}>
          <Iconify icon="eva:edit-fill" sx={{ mr: 2 }} />
          {t("admin_user.table.row.edit_button", "Edit")}
        </MenuItem>
      </Popover>
    </>
  );
}

UserTableRow.propTypes = {
  handleClick: PropTypes.func,
  handleEditClick: PropTypes.func,
  selected: PropTypes.any,
  userProfile: PropTypes.instanceOf(UserProfile),
};  
