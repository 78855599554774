import { useTranslation } from 'react-i18next';

import { ServiceModelExecutionsView } from 'src/sections/admin-service-model-executions/view';

// ----------------------------------------------------------------------

export default function ServiceModelExecutionsPage() {
    const { t } = useTranslation();
    return (
        <ServiceModelExecutionsView />
    );
}
