import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { getUserProfile, adminGetUserProfile } from "src/utils/api";

import { ProfileView } from 'src/sections/profile/view';


// ----------------------------------------------------------------------

export default function ProfilePage() {
  const { t } = useTranslation();
  const { userID } = useParams();
  const [userProfile, setUserProfile] = useState(null);
  const [myUserProfile, setMyUserProfile] = useState(null);

  useEffect(() => {
    (async () => {
      const { userProfile: myUserProfile_, err: err_ } = await getUserProfile();
      if (err_) return;
      setMyUserProfile(myUserProfile_);

      if (userID && userID !== myUserProfile_.userID) {
        const { userProfile: userProfile_, err } = await adminGetUserProfile({ userID });
        if (err) return;
        setUserProfile(userProfile_);
      } else {
        setUserProfile(myUserProfile_);
      }
    })();
  }, [userID]);

  // viewAsAdmin has been set to true, if the page is accessed by an admin and the request route path including userID 
  // which means it was navigated from admin section otherwise it would access from user section which does not include userID

  // we need to add condition userID (path param) must be valid, because if the user himself is admin, when he enter his profile page (via user section)
  // he will see the profile page as an admin

  let viewAsAdmin = false;
  if (userID) {
    if (myUserProfile && myUserProfile.isAdmin) {
      viewAsAdmin = true;
    }
  }

  return (
    <>
      {userProfile ? <ProfileView userProfile={userProfile} viewAsAdmin={viewAsAdmin} /> : null}
    </>
  );
}