import i18n from 'i18next'
// import XHR from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'

import transEN from "./locales/en/translation.json";
import { getCurrentLanguage } from './utils/utilities';
import { listAppLocalizations, syncPreferredLanguage } from './utils/api';

const resources = {
    en: {
        translation: transEN,
    },
};

i18n
    .use(initReactI18next)
    .init({
        // resources,
        lng: getCurrentLanguage(),
        interpolation: {
            escapeValue: false,
        }
    }).then(() => {
        (async () => {
            const { localizations: langs, err } = await listAppLocalizations()
            if (err) {
                console.error("failed to list app localizations:", err);
                return;
            }

            langs.forEach((lang) => {
                i18n.addResourceBundle(lang.languageCode, "translation", JSON.parse(lang.resource));
            });

            // call syncPreferredLanguage to update the preferred language once after i18n is initialized
            syncPreferredLanguage();

            i18n.changeLanguage(getCurrentLanguage());

        })();
    });

export default i18n