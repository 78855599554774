// ----------------------------------------------------------------------

// original
// export const HEADER = {
//   H_MOBILE: 64,
//   H_DESKTOP: 80,
//   H_DESKTOP_OFFSET: 80 - 16,
// };
// export const HEADER = {
//   H_MOBILE: 64,
//   H_DESKTOP: 120,
//   H_DESKTOP_OFFSET: 120 - 16,
// };

export const HEADER = {
  H_MOBILE: 0,
  H_DESKTOP: 0,
  H_DESKTOP_OFFSET: 0,
};

export const NAV = {
  WIDTH: 280,
  SMALL_WIDTH: 54,
};

export const appBarDecorateLineColor = '#005ae1';
// export const pageTopMargin = 6;
export const pageTopMargin = 1;