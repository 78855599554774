import { useTranslation } from "react-i18next";

import { LocalizationView } from 'src/sections/admin-localization/view';

// ----------------------------------------------------------------------

export default function AdminLocalizationPage() {
    const { t } = useTranslation();
    return (
        <LocalizationView />
    );
}
