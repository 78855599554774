import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import { alpha } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import ListItemButton from '@mui/material/ListItemButton';

import { usePathname } from 'src/routes/hooks';
import { RouterLink } from 'src/routes/components';

import { useResponsive } from 'src/hooks/use-responsive';

import EnvWrapper from "src/env-wrapper";
import generatedGitInfo from "src/generatedGitInfo";

import Scrollbar from 'src/components/scrollbar';
import OnshoreTypographyLogo from 'src/components/onshore-typography-logo';

import navConfig, { navConfigAdmin } from './config-navigation';
import { NAV, HEADER, appBarDecorateLineColor } from './config-layout';

// ----------------------------------------------------------------------

export default function Nav({ userProfile, openNav, onCloseNav }) {
  const pathname = usePathname();

  const upLg = useResponsive('up', 'lg');

  const { t } = useTranslation();

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderAccount = (
    <Box
      sx={{
        my: 3,
        mx: 2.5,
        py: 2,
        px: 2.5,
        display: 'flex',
        borderRadius: 1.5,
        alignItems: 'center',
        bgcolor: (theme) => alpha(theme.palette.grey[500], 0.12),
      }}
    >
      <Avatar alt="photoURL" sx={{ bgcolor: 'primary.main' }}>
        {userProfile && userProfile.firstName.length > 0 ? userProfile.firstName.charAt(0).toUpperCase() : ""}
      </Avatar>

      {
        userProfile ?
          <Box sx={{ ml: 2 }}>
            <Typography variant="subtitle2">{userProfile.firstName}</Typography>
          </Box> :
          <Box sx={{ ml: 2 }}>
            <Typography variant="subtitle2">-</Typography>
            {/* <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {account.role}
            </Typography> */}
          </Box>
      }
    </Box>
  );


  const renderAccountSmall = (
    <Box
      sx={{
        my: 1,
        py: 2,
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
      }}
    >
      <Avatar alt="photoURL" sx={{ bgcolor: 'primary.main', width: 28, height: 28 }}>
        {userProfile && userProfile.firstName.length > 0 ? userProfile.firstName.charAt(0).toUpperCase() : ""}
      </Avatar>
    </Box>
  );

  const renderMenu = (
    <Stack component="nav" spacing={0.5} sx={{ px: 2 }}>
      {navConfig(t).map((item) => (
        <NavItem key={item.title} item={item} />
      ))}

      {userProfile && userProfile.isAdmin ? <>
        <Typography variant="subtitle2" >{t("side_menu.admin_section_title", "Admin Section")}</Typography>
        {navConfigAdmin(t).map((item) => (
          <NavItem key={item.title} item={item} />
        ))}
      </> : null}

      <Divider />

      <Typography variant="caption" textAlign="center" color="GrayText">
        <br />
        <br />
        Web app version: {EnvWrapper.WEB_VERSION()}<br />
        Build: {`${generatedGitInfo.gitBranch}-${generatedGitInfo.gitCommitHash}${generatedGitInfo.wip ? " (WIP)" : ""}`}
      </Typography>

    </Stack>
  );

  const renderMenuSmall = (
    <Stack component="nav" spacing={0.5} sx={{ justifyContent: "center", }}>
      {navConfig(t).map((item) => (
        <NavItemIcon key={item.title} item={item} hideItemName />
      ))}

      {userProfile && userProfile.isAdmin ? <>
        <Divider />
        <Typography sx={{ p: 1.5 }} variant="caption" textAlign="center" >{t("side_menu.admin_section_title_short", "Admin")}</Typography>

        {navConfigAdmin(t).map((item) => (
          <NavItemIcon key={item.title} item={item} hideItemName />
        ))}
      </> : null}

    </Stack>
  );

  const showLogo = false;

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      {
        showLogo ? <>
          <Box height={10} sx={{ backgroundColor: appBarDecorateLineColor }} />
          <Box height={HEADER.H_DESKTOP} display="flex" alignItems="center" justifyContent="center">
            <OnshoreTypographyLogo />
          </Box>
        </> : null
      }

      {/* <Logo sx={{ mt: 3, ml: 4 }} /> */}

      {renderAccount}

      {renderMenu}

      <Box sx={{ flexGrow: 1 }} />

    </Scrollbar>
  );

  const renderContentSmall = (
    <Box
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >

      {renderAccountSmall}

      {renderMenuSmall}

      <Box sx={{ flexGrow: 1 }} />

    </Box>
  );

  return (
    <Box
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.WIDTH },
      }}
    >
      {upLg ? (
        <Box
          sx={{
            height: 1,
            // position: 'fixed',
            width: NAV.WIDTH,
            borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          }}
        >
          {renderContent}
        </Box>
      ) : (
        <Box
          sx={{
            height: 1,
            // position: 'fixed',
            width: NAV.SMALL_WIDTH,
            borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          }}
        >
          {renderContentSmall}
        </Box>
      )}
    </Box>
  );
}

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
  userProfile: PropTypes.object,
};

// ----------------------------------------------------------------------

function NavItem({ item }) {
  const pathname = usePathname();

  const active = item.path === pathname || (pathname === "/" && item.isDefaultPath);

  return (
    <ListItemButton
      component={RouterLink}
      href={item.path}
      sx={{
        minHeight: 44,
        borderRadius: 0.75,
        typography: 'body2',
        color: 'text.secondary',
        textTransform: 'capitalize',
        fontWeight: 'fontWeightMedium',
        ...(active && {
          color: 'primary.main',
          fontWeight: 'fontWeightSemiBold',
          bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
          '&:hover': {
            bgcolor: (theme) => alpha(theme.palette.primary.main, 0.16),
          },
        }),
      }}
    >
      <Box component="span" sx={{ width: 24, height: 24, mr: 2 }}>
        {item.icon}
      </Box>
      <Box component="span">{item.title} </Box>
    </ListItemButton>
  );
}

NavItem.propTypes = {
  item: PropTypes.object,
};


function NavItemIcon({ item }) {
  const pathname = usePathname();
  const active = item.path === pathname || (pathname === "/" && item.isDefaultPath);

  return (
    <ListItemButton
      component={RouterLink}
      href={item.path}
      sx={{
        minHeight: 44,
        borderRadius: 0.75,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'text.secondary',
        ...(active && {
          color: 'primary.main',
          fontWeight: 'fontWeightSemiBold',
          bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
          '&:hover': {
            bgcolor: (theme) => alpha(theme.palette.primary.main, 0.16),
          },
        }),
      }}
    >
      <Box component="span" sx={{ width: 24, height: 24, }}>
        {item.icon}
      </Box>
    </ListItemButton>
  );
}

NavItemIcon.propTypes = {
  item: PropTypes.object,
};
