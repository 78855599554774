import { XModel } from "./xmodel";

export class UserProfile extends XModel {
  constructor() {
    super();
    this.userID = "";
    this.name = "";
    this.firstName = "";
    this.lastName = "";
    this.phoneNumber = "";
    this.email = "";
    this.jobTitleOrRole = "";
    this.companyName = "";
    this.companyAddress = "";
    this.vatNumber = "";
    this.isAdmin = false;
    this.isRequirePasswordChanging = false;

    // extended fields ...
    this.isActive = false;
  }

  static fromObject(obj) {
    const m = new UserProfile();
    Object.assign(m, obj);
    return m;
  }
}