import { pad, enc } from "crypto-js"
import { decrypt as decrypt_, encrypt as encrypt_ } from "crypto-js/aes"

const iv = enc.Utf8.parse("1234567812345678");

// console.log("decrypted >>>", decrypt("wxPMlx9GO//UElWhcrVOMw==", "zqsqva28xfcfaw65rkb4r8qp2y7xvbru"));
// console.log("encrypted >>>", encrypt("hello world", "zqsqva28xfcfaw65rkb4r8qp2y7xvbru"));

/**
 * 
 * @param {string} str a plain text
 * @param {string} key 32 length key
 * @returns encrypted in base64 format
 */
export function encrypt(str, key) {
  key = enc.Utf8.parse(key);
  const encrypted = encrypt_(str, key, {
    iv,
    padding: pad.Pkcs7
  });
  return encrypted.toString();
}

/**
 * 
 * @param {string} str the encrypted string in base64
 * @param {string} key 
 * @returns decrypted text in utf8
 */
export function decrypt(str, key) {
  key = enc.Utf8.parse(key);
  const decrypted = decrypt_(str.toString(), key, {
    iv,
    padding: pad.Pkcs7
  });
  return decrypted.toString(enc.Utf8);
}