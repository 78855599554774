import { useTranslation } from 'react-i18next';

import { ServiceModelView } from 'src/sections/admin-service-model/view';

// ----------------------------------------------------------------------

export default function AdminServiceModelPage() {
    const { t } = useTranslation();
    return (
        <ServiceModelView />
    );
}
