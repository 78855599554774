import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';

import { useRouter } from 'src/routes/hooks';

import { adminListUsers } from 'src/utils/api';

import { UserProfile } from 'src/model/profile';
import { pageTopMargin } from 'src/layouts/dashboard/config-layout';

import SectionHeader from 'src/components/section-header/header';

import UserTableRow from '../user-table-row';
import UserTableHead from '../user-table-head';
import UserTableToolbar from '../user-table-toolbar';
import { applyFilter, getComparator } from '../utils';


// ----------------------------------------------------------------------

export default function UserPage() {
  const { t } = useTranslation();

  const router = useRouter();

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [userProfiles, setUserProfiles] = useState([].map(e => UserProfile.fromObject(e)));

  const handleSort = (event, id) => {
    const isAsc = orderBy === id && order === 'asc';
    if (id !== '') {
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(id);
    }
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const dataFiltered = applyFilter({
    inputData: userProfiles,
    comparator: getComparator(order, orderBy),
    filterName,
  });

  const notFound = !dataFiltered.length && !!filterName;

  useEffect(() => {
    (async () => {
      const { userProfiles: userProfiles_, err } = await adminListUsers();
      if (err) {
        return;
      }
      setUserProfiles(userProfiles_);
    })();
  }, []);

  return (
    <Container sx={{ mt: pageTopMargin }}>
      <SectionHeader>
        <Typography variant="h5">{t("admin_user.header", "Users")}</Typography>
      </SectionHeader>

      <Card>
        <UserTableToolbar
          numSelected={selected.length}
          filterName={filterName}
          onFilterName={handleFilterByName}
        />

        {/* <Scrollbar> */}
        <TableContainer sx={{ overflow: 'unset' }}>
          <Table sx={{ minWidth: 800 }}>
            <UserTableHead
              order={order}
              orderBy={orderBy}
              rowCount={userProfiles.length}
              numSelected={selected.length}
              onRequestSort={handleSort}
              onSelectAllClick={null}
              headLabel={[
                { id: 'name', label: t("admin_user.table.header.name", "Name") },
                { id: 'email', label: t("admin_user.table.header.email", "Email") },
                { id: 'company', label: t("admin_user.table.header.company", "Company") },
                { id: 'role', label: t("admin_user.table.header.role", "Role") },
                { id: 'isActive', label: t("admin_user.table.header.is_active", "Is active"), align: 'center' },
                { id: '' },
              ]}
            />
            <TableBody >
              {dataFiltered
                .slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage)
                .map((row) => (
                  <UserTableRow
                    key={row.id}
                    userProfile={row}
                    selected={selected.indexOf(row.name) !== -1}
                    handleClick={(event) => handleClick(event, row.name)}
                    handleEditClick={() => {
                      router.push(`/admin/users/${row.userID}/profile`);
                    }}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/* </Scrollbar> */}

        <TablePagination
          page={page}
          component="div"
          count={userProfiles.length}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[5, 10, 25]}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </Container>
  );
}
