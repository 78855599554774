import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Table, Paper, Button, TableRow, TableBody, TableCell, TableHead, TableContainer } from '@mui/material';

import Iconify from 'src/components/iconify';

export default function LocalizationTable({ localizations, sx, onEdit }) {
  const { t } = useTranslation();

  return (
    <TableContainer component={Paper} sx={{ ...sx }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t("admin_localization.dialog.table.header.language_code", "Language Code")}</TableCell>
            <TableCell>{t("admin_localization.dialog.table.header.language", "Language")}</TableCell>
            <TableCell>{t("admin_localization.dialog.table.header.icon", "Icon")}</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {localizations.map((localization) => (
            <TableRow key={localization.languageCode}>
              <TableCell>{localization.languageCode}</TableCell>
              <TableCell>{localization.language}</TableCell>
              <TableCell><Iconify icon={localization.iconifyName} /></TableCell>
              <TableCell>
                <Button variant="outlined" onClick={() => {
                  onEdit({ ...localization })
                }}>
                  {t("admin_localization.dialog.table.row.edit_button", "Edit")}
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

LocalizationTable.propTypes = {
  localizations: PropTypes.array,
  sx: PropTypes.object,
  onEdit: PropTypes.func,
};
