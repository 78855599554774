import EnvWrapper from "src/env-wrapper";
import { wordPressPageRoute } from "src/constants/wordpress"

/**
 * resolve the given route, depending on settings
 * + if it is built for wordpress integration, resolveRoute(...) may replace the given route with path 
 *   that copatible with the wprdpress page (using reactpress)
 * @param {string} route 
 * @returns 
 */
export function resolveRoute(route) {
    const r = `#/${route}`;
    if (EnvWrapper.RESOLVE_ROUTE_FOR_WORDPRESS()) {
        return wordPressPageRoute(r);
    }
    return `/${r}`;
}